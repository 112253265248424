.promotion_popup {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100vw;
  height: 100vh;

  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // background-color: #f5f5f5;
    opacity: 0.9;
    z-index: 1;
    backdrop-filter: blur(2px);
  }

  .mainContent {
    background-color: white;
    border-radius: 10px;
    // padding: 20px;
    display: flex;
    // flex-wrap: wrap;
    gap: 50px;
    z-index: 2;
    max-width: 80%;
    box-shadow: 6px 8px 10px rgba(0, 0, 0, 0.1);

    .main_image {
      img {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
    }
  }

  .second_section {
    display: flex;
    flex-direction: column;
    max-width: 350px;
    padding-right: 20px;

    .cancel {
      display: flex;
      justify-content: flex-end;

      img {
        cursor: pointer;
      }
    }

    .headingContent {
      margin-top: 30px;

      .main_heading {
        font-size: 50px;
        line-height: 0.4;
      }

      .sub_heading {
        color: red;
        font-size: 50px;
        line-height: 0.4;
      }
    }

    .textContent {
      margin-top: 10px;

      .mainText {
        font-weight: 500;
        font-size: 16px;
      }

      .subText {
        margin-top: 10px;
        color: 44ee4e;
      }
    }

    .button {
      background-color: #e23d1d;
      border-radius: 10px;
      padding: 10px 20px;
      border: 0;
      outline: 0;
      color: #fff;
      width: 150px;
      margin-top: 10px;
      cursor: pointer;

      :hover {
        border: #dd3513;
      }
    }
  }
}
