@import 'variables.scss';

.selectedWrapTitle {
  font-size: 14px;
  padding: 10% 0;
  width: 100%;
  background-color: $bg_selected_tab;
  font-weight: bold;
  justify-content: end;
  padding-left: 10px;
  display: block;
  transition: background-color, padding-left 0.3s, 1s ease linear;
  position: relative;
  box-sizing: border-box;
}

.notSelectWrapTitle {
  padding: 10% 0;
  cursor: pointer;
  width: 100%;
  font-size: 14px;
  justify-content: end;
  display: block;
  box-sizing: border-box;
  position: relative;
}

.iconNew {
  position: absolute;
  right: 7px;
  top: 10px;

  svg {
    path {
      color: green;
    }
  }
}
