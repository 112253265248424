@import '../../variables.scss';

.root {
  display: flex;
  flex-direction: column;
  align-items: baseline;

  .title {
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #333333;
    margin-top: 10px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .content {
    display: flex;

    .input {
      width: 300px !important;
    }
  }
}
