.root {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(../../assets/img/bg_login.png);
  background-size: cover;

  .successNoteContainer {
    width: 500px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border-radius: 5px;
    box-shadow: -1px 2px 14px 0px rgba(0, 0, 0, 0.75);
    padding: 20px;

    .successWrap {
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 10px;

      .successPharagraph {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .icon {
      color: green;
    }
  }

  .errorContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 500px;
    height: 200px;
    background: #fff;
    border-radius: 5px;
    box-shadow: -1px 2px 14px 0px rgba(0, 0, 0, 0.75);
  }

  .passContainer {
    width: 425px;
    height: 100px;
    background: #fff;
    border-radius: 5px;
    box-shadow: -1px 2px 14px 0px rgba(0, 0, 0, 0.75);
    display: flex;
    flex-direction: column;
    padding: 20px;

    .pharagraph {
      font-weight: bold;
      font-size: 16px;
      margin-top: 0;
      margin-bottom: 20px;
    }

    .passInputWrapper {
      display: flex;
      align-items: center;

      .btn {
        height: 44px;
        width: 90px;
        margin-bottom: 20px;
        border: none;
        cursor: pointer;
        background: #343b4c;
        border-radius: 15px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        color: #fff;
        margin-left: 12px;
      }
    }
  }
}
