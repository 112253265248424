@import '../../variables.scss';

.root {
  min-height: calc(100vh - 60px);
  overflow: hidden;
  background: $site_bg;
  display: flex;
  justify-content: center;
  width: 100%;

  .content {
    width: calc(100% - 14px);
    min-width: 0;
    margin: 0 auto;
    display: flex;
    overflow-y: auto;
  }
}

.tabsContainer {
  display: flex;
  flex-direction: column;
  width: 100px;
}
