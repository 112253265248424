@import 'variables.scss';

.root {
  background: $site_bg;
  display: flex;
  justify-content: center;
  height: 100%;

  .content {
    width: calc(100% - 73px);
    height: calc(100% - 60px);
    min-height: 300px;
    padding: 20px;
    background: #fff;
    border-radius: 15px;
    overflow: auto;
    box-shadow: 2px 1px 11px 3px rgba(181, 181, 181, 0.4);
  }
}
