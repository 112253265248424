.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 12px;

  .subhead {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 0 5px 0;
    box-sizing: border-box;
    .title {
      margin-top: 0;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      color: #333333;
      -webkit-user-select: none;
      user-select: none;
      width: 100px;
    }

    .btn {
      background: none;
      border: none;
      font-style: normal;
      font-weight: 600;
      font-size: 11px;
      line-height: 15px;
      text-transform: uppercase;
      color: #464646;
      cursor: pointer;
      outline: none;
      margin: 0px 10px;
      border-radius: 15px;

      &.hidden {
        display: none;
      }

      &.selected {
        background: #eef2f4;
      }

      &.disabled {
        cursor: not-allowed;
      }
    }

    .checkbox {
      margin-left: auto;
    }
  }
}

.label {
  font-weight: 600;
  font-size: 12px;
  line-height: 0;
  color: #333333;
  cursor: pointer;

  &.selected {
    fill: #3f51b5;
  }
}

.search {
  width: 100%;
}
