@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700,900&display=swap&subset=cyrillic');

@import './variables.scss';
@import './helpers.scss';

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}

.d-none {
  display: none;
}

.d-flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.d-block {
  display: block;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.align-start {
  align-items: flex-start;
}

.align-baseline {
  align-items: baseline;
}

.justify-center {
  justify-content: center;
}

.justify-around {
  justify-content: space-around;
}

.justify-between {
  justify-content: space-between;
}

.justify-evenly {
  justify-content: space-evenly;
}

.flex-column {
  flex-direction: column;
}

.tac {
  text-align: center;
}

.inp-sign {
  margin: 5px 0px;
}

.inp-primary {
  width: 320px;
  height: 45px;
  background: $gray_three;
  border: 2px solid $gray_fourth;
  box-sizing: border-box;
  font-family: Montserrat;
  border-radius: 15px;
  padding-left: 16px;

  &::placeholder {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #8d96b2;
  }
}

.btn-primary {
  width: 320px;
  height: 45px;
  background: #343b4c;
  border-radius: 15px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  border: none;
  text-align: center;
  cursor: pointer;
  color: $white;
  user-select: none;

  &:disabled {
    cursor: not-allowed;
    background: #565c69;
  }
}

.btn {
  width: 320px;
  height: 45px;
  background: $white;
  border-radius: 15px;
  border: none;
  cursor: pointer;
}

.btn-header {
  width: 210px;
  height: 32px;
  background: $white;
  border-radius: 15px;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: $gray_two;
  border: none;
  cursor: pointer;
}

.btn-track {
  width: 87px;
  height: 32px;
}

.button-secondary {
  cursor: pointer;
  width: 320px;
  height: 45px;
  border: none;
  background: $gray_one;
  border-radius: 15px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;

  text-align: center;
  color: $white;
  text-transform: uppercase;
}

.error-notif {
  animation: show 3000 ease;
  font-size: 11px;
  color: $red;

  @keyframes show {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}

.show-animation {
  opacity: 1;
  animation: show 600ms ease-in;
  animation-iteration-count: 1;

  @keyframes show {
    0% {
      opacity: 0.4;
    }

    100% {
      opacity: 1;
    }
  }
}

.m-auto {
  margin: 0px auto;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.loading-container {
  width: 100%;
  height: 100vh;
  background: $white;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: 100;
}

.curs-pointer {
  cursor: pointer;
}

.sort-arrow {
  height: 15px !important;
  color: #8d96b2;
}

.rhap_current-time {
  margin-left: 10px !important;
}

.rhap_progress-filled {
  border-radius: 0 !important;
}

.rhap_progress-indicator {
  border-radius: 0 !important;
}

button {
  outline: none;
}

.zindex100000 {
  z-index: 100000 !important;
}

.pos-rel {
  position: relative;
}

.manager_audiopanel_container {
  width: 100% !important;
}

.simple-option,
.no-results-found {
  font-size: 12px !important;
  height: 30px !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

.simple-option {
  border-bottom: 1px solid #eef2f457;
  border-top: 1px solid #eef2f457;
}

.disabled {
  cursor: not-allowed !important;

  &:hover {
    cursor: not-allowed !important;
  }

  opacity: 0.5 !important;
}

.slow-1000 {
  transition: all 1s ease !important;
}

html {
  max-height: 100vh;
  width: 100%;
  overflow: hidden;
}

.manager-content {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.manager-panels {
  width: calc(100% - 20px);
  height: calc(100% - 185px);
}

.crisp-client .cc-kv6t .cc-1xry .cc-ge4v,
.crisp-client .cc-kv6t .cc-1xry .cc-unoo {
  position: fixed !important;
  right: 12px !important;
}

.crisp-client .cc-kv6t .cc-1xry .cc-unoo {
  z-index: 2 !important;
  display: block !important;
  bottom: 12px !important;
}

input {
  -webkit-user-select: text;
  user-select: text;
}

.swal-container {
  right: 80px !important;
}

.mobile-window {
  width: 100%;
  padding: 20px;
  height: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  position: relative;
  justify-content: center;

  .mobile-img {
    width: 100%;
    height: auto;
    margin-bottom: 40px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .mobile-text {
    text-align: center;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #343b4c;

    .mobile-name {
      font-weight: bold;
    }
  }
}
