.promotion_popup {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100vw;
  height: 100vh;

  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // background-color: #f5f5f5;
    opacity: 0.9;
    z-index: 1;
    backdrop-filter: blur(2px);
  }

  .mainContent {
    background-color: white;
    border-radius: 10px;
    // padding: 20px;
    display: flex;
    // flex-wrap: wrap;
    gap: 50px;
    z-index: 2;
    max-width: 80%;
    box-shadow: 0px 0px 9px #00000045;
  }

  .second_section {
    display: flex;
    flex-direction: column;
    max-width: 560px;
    padding: 20px;

    .headingContent {
      background: linear-gradient(145deg, #1e1e1e, #292929);
      border-radius: 10px;
      padding: 20px;
      text-align: center;
      margin-top: 0px;

      .main_heading {
        font-size: 50px;
        color: white;
        line-height: normal;
        text-align: center;
        margin: 10px 0px;
        font-weight: 500;
      }

      .sub_heading {
        color: red;
        font-size: 50px;
        line-height: 0.4;
      }
    }

    .textContent {
      margin-top: 20px;

      .mainText {
        font-weight: 500;
        font-size: 16px;
      }

      .subText {
        margin-top: 10px;
        line-height: 22px;
      }
    }

    .button {
      background-color: #e23d1d;
      border-radius: 10px;
      padding: 10px 20px;
      border: 0;
      outline: 0;
      color: #fff;
      width: 150px;
      margin-top: 10px;
      cursor: pointer;

      :hover {
        border: #dd3513;
      }
    }
  }
}

.promotion_popup .second_section .btnsWrapper {
  display: flex;
}

.promotion_popup .second_section .button {
  font-size: 18px;
  border-radius: 10px;
  padding: 10px 20px;
  border: 0;
  outline: 0;
  cursor: pointer;
  color: #fff;
  margin: auto;
}


.promotion_popup .second_section .button.privacyPolicy {
  width: 250px;
  background-color: #e23d1d;
}

.promotion_popup .second_section .button.agreeToUpdate {
  background: linear-gradient(145deg, #1e1e1e, #292929);
  width: 150px;
}

.promotion_popup .second_section .button {
  font-size: 18px;
  border-radius: 10px;
  padding: 10px 20px;
  border: 0;
  outline: 0;
  cursor: pointer;
  color: #fff;
  margin: auto;
  width: 250px;
  background-color: #e23d1d;
  position: relative;
  overflow: hidden;
  transition: background-color 0.3s ease-in-out;
}

.promotion_popup .second_section .button::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(120deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0.2) 100%);
  transform: skewX(-25deg);
  transition: left 0.5s ease-in-out;
}

.promotion_popup .second_section .button:hover::before {
  left: 100%;
}

.promotion_popup .second_section .button:hover {
  background-color: #d13214;
}
