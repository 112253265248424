.root {
  padding: 40px;
  box-sizing: border-box;
  background: #fff;
  height: calc(100% - 80px);

  .container {
    max-width: 1000px;
    margin: 0px auto;
    overflow: auto;
    height: 100%;
    padding-right: 10px;
    box-sizing: border-box;

    .newText {
      text-align: justify;
      line-height: 24px;
    }
  }
}
