.root,
text[text-anchor='end'],
text[text-anchor='middle'] {
  font-size: 12px;
}

.checkbox {
  & ~ span {
    color: #333333;
    font-size: 12px !important;
    line-height: 18px;
  }
}
