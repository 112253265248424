.sign-up-link {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  color: #9198b2;
}

.sign-link {
  margin-left: 5px;
}

.btn-log-in {
  flex-shrink: 0;
  transition: all 0.5s ease;
  height: 50px;
  &:hover {
    color: rgb(214, 214, 214);
    background: #516086;
  }
}
.input-for-sign-up {
  margin-bottom: 5px;
}
