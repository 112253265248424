@import 'variables.scss';

.root {
  width: auto;
  height: auto;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.select {
  margin-bottom: 20px;
}

.text {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: $gray_one;
}