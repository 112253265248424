.root {
  display: flex;
  align-items: center;
  height: 40px;
  border-bottom: 1px solid #efefef;
  background: inherit;
  transition: all 1s linear;

  &:first-of-type {
    border-top: 1px solid #efefef;
  }

  &.selected {
    background: #eef2f4;
    transition: all 0.5s ease;
  }

  .btnImg {
    width: 13px;
    height: 13px;
    margin-right: 15px;
  }

  .btnSelect,
  .btnDelete {
    background: inherit;
    border: none;
    outline: none;
    cursor: pointer;
  }

  .btnDelete {
    margin-left: 7px;
  }

  .title {
    text-overflow: ellipsis;
    overflow: hidden;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    color: #333333;
    font-size: 11px;
    width: 230px;
  }
}
