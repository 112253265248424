.root {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 40px;
}

.switch {
  width: 40px;
  height: 24px;
  background: rgba(111, 128, 155, 0.3);
  border-radius: 20px;
  display: flex;
  align-items: center;
  transition: all 1s ease;
  user-select: none;

  .switchRing {
    margin-top: -1px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin-right: 2px;
    margin-left: 2px;
    background: #203a62;
    transition: all 600ms ease-in;
    cursor: pointer;

    &:hover {
      box-shadow: 0px 0px 7px 1px rgba(4, 7, 46, 0.05), 0px 0px 9px 4px rgba(7, 65, 133, 0.01), 0px 0px 7px 6px rgba(5, 6, 10, 0.05);
    }
  }
}

.checked {
  transition: all 1s ease-in-out;
  justify-content: flex-end;
}

.notChecked {
  transition: all 1s ease-in-out;
  justify-content: flex-start;
}

.swithLabelContainer {
  display: flex;
  align-items: center;
  transition: all 1s ease-in-out;

  .switchLabelLeft,
  .switchLabelRight {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
  }

  .switchLabelLeft {
    margin-right: 12px;
    transition: all 1s ease-in-out;
  }

  .switchLabelLeft.selected,
  .switchLabelRight.selected {
    // color: #203a62;
    color: #333333;
    transition: all 1s ease-in-out;
  }

  .switchLabelLeft.notSelected,
  .switchLabelRight.notSelected {
    // color: rgba(111, 128, 154, 0.8);
    color: #8d96b2;
    transition: all 1s ease-in-out;
  }

  .switchLabelRight {
    transition: all 1s ease-in-out;

    margin-left: 12px;
  }

  .switchShare {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    // color: #74ca77;
    color: green;
    margin-left: 4px;
  }
}