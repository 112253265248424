@import '../../variables.scss';

.root {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background-color: #c4c4c4;
  opacity: 1;
  background: no-repeat url(../../assets/img/log-min.jpg);
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;

  .wrapper {
    max-width: 429px;
    width: 100%;
    height: 449px;
    background: rgba(255, 255, 255, 0.925);
    border-radius: 15px;
    margin: 10px 0px;

    .version {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #9198b2;
      margin-top: 5px;
    }

    .forget {
      display: block;
      text-align: center;
      font-size: 14px;
    }
  }
}
