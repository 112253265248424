@import 'mixins.scss';

.promotion_popup {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100vw;
  height: 100vh;

  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // background-color: #f5f5f5;
    opacity: 0.9;
    z-index: 1;
    backdrop-filter: blur(2px);
  }
}

.root {
  width: 100%;
  background: #fff;
  padding: 40px 0;
  box-sizing: border-box;
  overflow: hidden;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 15px;
  max-width: 1000px;
  line-height: 1.6;
  text-align: justify;
  box-shadow: 0px 0px 9px #00000045;
  z-index: 2;

  .wrapper {
    padding: 0 40px 40px 40px;
    overflow: auto;
    height: calc(100vh - 200px);
    box-sizing: border-box;
  }

  .subtextContainer {
    padding-left: 20px;
    box-sizing: border-box;
    display: flex;
    margin-top: 15px;

    .bold {
      font-weight: bold;
      margin-right: 15px;
    }
  }
}

.bold {
  font-weight: bold;
}

.subContainer {
  padding-left: 40px;
  box-sizing: border-box;
  display: flex;
  margin-top: 15px;

  .boldText {
    font-weight: bold;
    margin-left: 15px;
  }
}

.message {
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
}

.btn {
  @include btnPrimary;
  margin-top: 50px;
  margin-left: 20px;
  display: block;
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.3);
    transform: skewX(-30deg);
    transition: left 0.6s ease-in-out;
  }

  &:hover::before {
    left: 100%;
  }
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.subHead {
  display: flex;

  .number {
    margin-right: 6px;
  }
}