.root {
  padding: 20px;
  margin-left: 10px;
  margin-top: 10px;
  box-sizing: border-box;
  width: 100%;
  background: #fff;
  border-radius: 15px;
  box-shadow: 2px 1px 11px 3px rgba(181, 181, 181, 0.4);
}
