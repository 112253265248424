@import 'variables.scss';
@import 'mixins.scss';

.mus-track {
  &-wrapper-selected {
    background: $gray_fourth;

    .mus-track-tr-type {
      background: $gray_two;
      color: $gray_fourth;
    }
  }

  &-edit {
    background: inherit;
    border: none;
    outline: none;
    cursor: pointer;
  }

  &-container {
    width: 100%;
    min-height: 54px;
    cursor: pointer;

    &-selected {
      background: $gray_fourth;
    }

    &-n {
      background: $white;
    }
  }

  &-name {
    font-style: normal;
    font-weight: 600;
    font-size: 11.5px;
    line-height: 22px;
    color: $gray_two;
    width: 50%;
    text-overflow: ellipsis;
    word-break: break-all;
    overflow: hidden;
    white-space: nowrap;
  }

  &-duration {
    font-weight: 500;
    font-size: 11px;
    line-height: 18px;
    color: $gray_one;
    margin-left: 10px;
  }

  &-desc {
    font-weight: 500;
    font-size: 11px;
    line-height: 18px;
    color: $gray_one;
    width: 100%;
    height: 32px;
    overflow: hidden;
    margin-right: 20px;
    text-overflow: ellipsis;
  }

  &-size {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: $gray_two;
    width: 80px;
    flex-shrink: 0;
    overflow: hidden;
    margin-right: 8px;
    height: 43px;
  }

  &-tags {
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 18px;
    color: $gray_one;
    overflow: hidden;
    margin-right: 25px;
    text-overflow: ellipsis;
    width: 100%;

    .mus-track-tag {
      display: block;
    }
  }

  &-writer {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    color: $gray_two;
    width: 50px;
    flex-shrink: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.btn-edit {
  width: 60px;
  height: 32px;
}

.mus-track-tr-type-container {
  width: 40px;
}

.mus-track-tr-type {
  font-size: 11px;
  transform: rotate(270deg);
  font-weight: bold;
  background: $gray_fourth;
  color: $gray_one;
  padding: 2px;
  width: 44px;
  text-align: center;
  border-radius: 3px;
  line-height: 1;
}

.mus-track-name-c {
  overflow: hidden;
}

.mus-track-artist-info {
  font-weight: 500;
  font-size: 11px;
  line-height: 18px;
  color: $gray_one;
  height: 32px;
  overflow: hidden;
  margin-right: 20px;
  text-overflow: ellipsis;
}

.mus-track-artist-name {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mus-track-container {
  display: grid;
  grid-template-columns: 40px 1.5fr 1.5fr 100px 1.5fr 1fr;
  grid-auto-rows: 65px;
}

.mus-track-buttons {
  display: flex;
  align-items: center;
}
