@import 'variables.scss';

.root {
  display: flex;
  flex-direction: column;
  height: 53px;
  width: 320px;
  flex-shrink: 0;

  .container {
    position: relative;
    height: 50px;

    .required {
      position: absolute;
      z-index: 2;
      left: 6px;
      top: 6px;
      color: tomato;
      width: 15px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }

  .error {
    font-size: 10px;
    color: red;
    margin-left: 10px;
    position: relative;
    bottom: 5px;
  }

  .input {
    width: 320px;
    height: 35px;
    background: $gray_three;
    border: 2px solid $gray_fourth;
    box-sizing: border-box;
    border-radius: 15px;
    position: absolute;
    top: 0px;
    left: 0px;
    outline: none;
    -webkit-user-select: text;
    user-select: text;
    font-size: 11px;

    &.inputError {
      border-bottom: 2px solid red;
    }

    &.disabled {
      background: rgba(230, 230, 230, 0.562);
      cursor: not-allowed;
    }

    &::placeholder {
      color: $placeholder;
      -webkit-user-select: text;
      user-select: text;
    }

    &.big {
      height: 50px;
    }
  }

  .inputControl {
    width: 45px;
    height: 31px;
    z-index: 1;
    top: 2px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #343b4c;
    right: 1px;
    border-bottom-right-radius: 13px;
    border-top-right-radius: 13px;
    right: 2px;

    &.big {
      height: 46px;
    }

    .controll {
      cursor: pointer;
      fill: rgba(230, 230, 230, 0.562);
    }
  }
}
