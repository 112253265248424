.root {
  width: calc(100% - 82px);
  height: 65px;
  border: 1px solid #e8e6e4;
  border-radius: 5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  position: fixed;
  bottom: 0;
  left: 80px;
  background: #fff;
  display: flex;
  align-items: center;

  .infoContainer {
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    color: #797979;
    width: 150px;

    .title {
      margin-right: 5px;
      width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: bold;
      text-align: right;
    }

    .description {
      margin-left: 5px;
    }
  }

  // .player {
  //   border: none;
  //   box-shadow: none;
  // }
}