@import 'variables.scss';

.root {
  padding: 0 20px 0 20px;
  box-sizing: border-box;
  position: relative;
  height: 180px;

  .header {
    display: flex;
    align-items: center;
  }

  .btn {
    background: inherit;
    padding: 0;
    border: none;
    margin-top: 10px;
    cursor: pointer;
  }

  .tracks {
    margin-top: 10px;
    overflow: hidden;
  }

  .trackTitle {
    margin-top: 5px;
    color: #242424;
    font-weight: 700;
    margin-bottom: 5px;
  }

  .track,
  .trackTitle {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #464646;
  }

  .addFirstTrack {
    width: 100%;
    height: 84px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
  }
}

.select {
  width: 200px !important;
}

.submitBtn {
  position: absolute;
  height: 30px;
  border: none;
  background: #343b4c;
  border-radius: 15px;
  color: #ffffff;
  transition: all 1s ease;
  font-weight: normal;
  font-size: 13px;
  cursor: pointer;
  bottom: 7px;
  padding: 2px 7px;
  right: 8px;
}

@media screen and(max-width: 1150px) {
  .select {
    width: 100% !important;
  }
}
