@import 'variables.scss';

.root {
  width: 100%;
  position: relative;
}

.error {
  color: $red;

  &.hidden {
    display: none;
  }
}

.loading {
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
}
