.root {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;

  .imgContainer {
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    margin-right: 10px;

    .img {
      width: 70px;
      max-width: 70px;
      height: auto;
      max-height: 70px;
    }
  }

  .top {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .status {
    padding: 2px;
    background: blue;
    color: #fff;
    border-radius: 4px;
    margin-right: 10px;
    font-size: 11px;
    position: absolute;
    right: -10px;
    top: 25px;
  }

  .content {
    display: flex;
    flex-direction: column;
    width: 100%;

    .hTitle,
    .company,
    .budget {
      margin: 0;
    }

    .hTitle {
      font-size: 12px;
      margin-right: auto;
    }

    .company,
    .uploadCount {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #464646;
      margin-top: 5px;
    }

    .uploadCount {
      font-weight: bold;
      margin-bottom: 0;
    }

    .budget,
    .limitBadge {
      color: #fafafa;
      background: #008000;
      padding: 2px 4px;
      border-radius: 3px;
      font-size: 11px;
      margin-left: 12px;
      max-width: 200px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .limitBadge {
      background: #f5a803;
    }
  }
}

.defaultIcon {
  width: 70px !important;
  height: 70px !important;
  fill: #eef2f4 !important;
}
