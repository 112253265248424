@import 'variables.scss';

.root {
  width: 80px;
  height: 920px;
  min-height: 100vh;
  background: $white;
  min-width: 80px;
  height: 100vh;

  .policy {
    font-weight: 500;
    font-size: 10px;
    margin-left: 5px;
  }

  .pricing {
    margin-top: 10px;

    .pricingLink {
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      flex-wrap: wrap;

      .linkName {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #112c46;
        opacity: 0.8;
      }
    }
  }

  .wrapper {
    position: relative;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80px;
    min-height: 100vh;
    background: $white;
    box-shadow: 2px 1px 11px 3px rgba(181, 181, 181, 0.1);
    height: 100vh;
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;

    .version {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 11px;
      color: #9198b2ad;
      margin-top: 5px;
    }
  }
}

.navigation {
  width: 100%;
  height: 100%;
  min-height: 200px;
  overflow: auto;
}

.footer {
  margin-top: auto;
  margin-bottom: 10px;
}

.settings {
  color: #464646;
  cursor: pointer;
  transition: all 0.4s linear;
  opacity: 1;

  &:hover {
    opacity: 0.9;
    transition: all 0.4s linear;
  }
}
