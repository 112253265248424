.root {
  display: flex;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
  row-gap: 10px;
  column-gap: 10px;
  font-size: 12px;
  font-weight: bold;
  margin-top: 5px;

  .column {
    width: 100px;
    display: flex;
    align-items: center;
    font-size: 11px;
  }
}
