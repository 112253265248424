.root {
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  background: #fff;
  box-shadow: 2px 1px 11px 3px rgba(181, 181, 181, 0.4);
  border-radius: 15px;
  margin-top: 20px;
  height: calc(100% - 360px);
  overflow: hidden;

  .list {
    overflow: auto;
    height: calc(100% - 140px);
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    column-gap: 10px;
  }

  .title {
    margin-top: 0;
  }
}

.addNew {
  display: flex;
  cursor: pointer;
  background: inherit;
  border-radius: 15px;
  color: #fff;
  border: none;
  background: #343b4c;
  font-size: 11px;
  padding: 5px;
  column-gap: 4px;
}
