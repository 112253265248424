@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500;600&display=swap');

.root {
  font-family: 'Poppins', sans-serif;
  width: 100%;
  height: 100%;
  background: #f2f6f9;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;

  .box {
    width: 350px;
    height: 250px;
    background: #ffffff;
    box-shadow: 0px 4px 6px rgba(12, 36, 71, 0.07);
    border-radius: 17px;
    padding: 30px;

    .iconContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      .icon {
        width: 40px;
        height: 40px;
      }
    }
    .head {
      font-weight: 600;
      font-size: 32px;
      line-height: 32px;
      color: #0c2447;
      text-align: center;
      margin-top: 20px;
    }
    .text {
      font-size: 18px;
      line-height: 24px;
      color: #0c2447;
      margin-top: 24px;
      text-align: center;
    }

    .btn {
      width: 256px;
      height: 56px;
      background: #3983f2;
      border-radius: 10px;
      border: none;
      outline: none;
      cursor: pointer;
      opacity: 1;
      transition: all 1s ease;
      margin: 30px auto 0 auto;
      display: block;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;

      .link {
        color: #ffffff;
        text-decoration: none;
        display: block;
        width: inherit;
        height: inherit;
        line-height: 56px;
      }

      &:hover {
        transition: all 1s ease;
        opacity: 0.8;
      }
    }
  }
}
