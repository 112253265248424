@import 'variables.scss';

.root {
  display: flex;
  align-items: center;
  justify-content: center;

  .btn {
    margin-top: 13px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #e43131;
    border: none;
    outline: none;
    background: inherit;
    cursor: pointer;
    transition: all 1s ease;
    font-family: 'Poppins', sans-serif;
  }
}

.popup {
  padding: 20px;
  .head {
    font-weight: bold;
    font-size: 18px;
    color: $gray_two;
    margin-bottom: 14px;
  }

  .text {
    font-weight: normal;
    font-size: 14px;
    color: $gray_five;
    margin-bottom: 14px;
  }
}
