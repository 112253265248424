@import 'variables.scss';
@import 'mixins.scss';

.root {
  padding: 20px 40px;
  box-sizing: border-box;
}

.avatarContainer {
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;

  .avatar {
    max-width: 100px;
    max-height: 100px;
    width: 100%;
    height: 100%;
    display: block;
  }
}

.topContent {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 20px;
}

.container {
  position: relative;
  border: 2px solid #caced3;
  border-radius: 20px;
  padding: 15px;
  min-width: 150px;

  .label {
    position: absolute;
    font-size: 12px;
    top: -10px;
    background: #fafafa;
    padding: 0px 5px;
  }

  .infoItem {
    font-size: 12px;
    color: $gray_two;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    column-gap: 5px;

    .left {
      font-weight: bold;
    }
  }
}

.verificationContainer {
  display: flex;
  align-items: center;
  column-gap: 10px;
  font-size: 12px;
  color: $gray_two;
  margin-top: 20px;
  margin-left: 14px;
  .verification {
    font-weight: bold;
  }
}

.popupContainer {
  padding-bottom: 20px;
  box-sizing: border-box;
}

.popupHead {
  @include table-column-name;
  margin-bottom: 20px;
}

.input {
  width: 100%;
  height: 35px;
  background: $gray_three;
  border: 2px solid $gray_fourth;
  box-sizing: border-box;
  border-radius: 15px;
  outline: none;
  -webkit-user-select: text;
  user-select: text;
  font-size: 11px;
  padding: 0 20px;
}

.emailContainer {
  position: relative;
}

.btnSend {
  position: absolute;
  right: 2px;
  border: none;
  background-color: transparent;
  top: 4px;
}

.icon {
  fill: green !important;
  cursor: pointer;

  &.disabled {
    fill: red !important;
    cursor: not-allowed;
  }
}

.addUrlButton,
.btn {
  background: $gray_two;
  border: 2px solid $gray_two;
  border-radius: 7px;
  color: $gray_three;
  cursor: pointer;
}

.imageItemWrapper {
  position: relative;
  text-align: center;
  max-width: 100%;
  max-height: 230px;

  &:hover {
    .text {
      opacity: 1;
    }
  }

  .text {
    position: absolute;
    color: white;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-shadow: rgb(11, 11, 11) 2px 0px 0px, rgb(11, 11, 11) 1.75517px 0.958851px 0px, rgb(11, 11, 11) 1.0806px 1.68294px 0px,
      rgb(11, 11, 11) 0.141474px 1.99499px 0px, rgb(11, 11, 11) -0.832294px 1.81859px 0px, rgb(11, 11, 11) -1.60229px 1.19694px 0px,
      rgb(11, 11, 11) -1.97998px 0.28224px 0px, rgb(11, 11, 11) -1.87291px -0.701566px 0px, rgb(11, 11, 11) -1.30729px -1.5136px 0px,
      rgb(11, 11, 11) -0.421592px -1.95506px 0px, rgb(11, 11, 11) 0.567324px -1.91785px 0px, rgb(11, 11, 11) 1.41734px -1.41108px 0px,
      rgb(11, 11, 11) 1.92034px -0.558831px 0px;
  }

  .copyLink {
    position: absolute;
    fill: green;
    position: absolute;
    top: 20px;
    left: 40px;
    transform: translate(-50%, -50%);
    cursor: pointer;

    &:hover {
      background: #000;
      fill: white;
    }
  }
}

.mainContent {
  width: 100%;
  height: 100%;
  min-height: 200px;
  padding: 20px;
  box-sizing: border-box;
  border-radius: $border_radius;
  background-color: #fff;
  margin-top: 20px;
  max-height: calc(100vh - 415px);
  overflow: hidden;
}

.imageItem {
  // max-width: 330px;
  max-height: 230px;
  // width: 100%;
  height: 230px;
}

.scrolledContent {
  overflow: auto;
  height: calc(100vh - 520px);
  @include scrollbar;
}

.bottomHeader {
  display: flex;
  align-items: center;
  column-gap: 20px;
  margin-top: 20px;

  .headItem {
    background: inherit;
    border: none;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    text-transform: uppercase;
    color: #464646;
    cursor: pointer;
    outline: none;
    padding: 0 10px;
    box-sizing: border-box;

    &.selected {
      background: #eef2f4;
      border-radius: 15px;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      color: #333333;
      border: none;
      cursor: pointer;
      outline: none;
    }
  }
}

.imageHead,
.jobsHead {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 15px;
}

.jobContainer,
.jobsHeader {
  display: grid;
  grid-template-columns: 3fr 1fr 100px 100px 100px 100px 100px 100px;
  align-items: center;
  padding: 10px 5px;
  width: 100%;
  box-sizing: border-box;
  font-size: 12px;
  color: #464646;

  &.extraPadding {
    padding-right: 14px;
    box-sizing: border-box;
  }

  .jobTitle {
    font-weight: bold;
    @include text-overflow;
  }

  .jobRowItem {
    @include text-overflow;
  }
}

.jobsHeader {
  font-weight: bold;
  border-bottom: 2px solid #333333;
  @include text-overflow;
}

.defaultAvatar {
  width: 100px;
  height: 100px;
  background-color: #eef2f4;

  display: flex;
  align-items: center;
  justify-content: center;
  .defaultIcon {
    width: 30px;
    height: 30px;
    fill: #fff;
  }
}

.imagesWrapper {
  display: flex;
  align-items: flex-start;
  row-gap: 20px;
  column-gap: 20px;
  flex-wrap: wrap;
}

.uploadContent {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 20px;
  width: 100%;
}

@media screen and (max-width: 1000px) {
  .avatarContainer {
    width: 60px;
    height: 60px;
  }
}
