@import 'mixins.scss';

.root {
  width: 100%;
  background: #fff;
  padding: 40px;
  box-sizing: border-box;
  height: calc(100vh - 200px);
  overflow: auto;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 15px;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  text-align: justify;

  .text {
    line-height: 1.6;
  }

  .btns {
    display: flex;
    align-items: center;
    margin-top: auto;
  }

  .btn,
  .btnBack {
    @include btnPrimary;
    margin-top: 50px;
    margin-left: 20px;
    display: block;
  }

  .btnBack {
    margin-left: auto;
    background: inherit;
    color: #343b4c;
    border: 2px solid #343b4c;
  }
}
