@import 'variables.scss';

.root {
  padding-top: 20px;
  box-sizing: border-box;
  position: relative;

  .loading {
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
  }
}
.title {
  font-size: 18px;
  font-weight: bold;
  color: $gray_two;
  margin-bottom: 20px;
}

.dropzone {
  width: 200px;
  margin-bottom: 30px;
}
.subtitle,
.text,
.bgTitle {
  font-weight: 500;
  font-size: 15px;
  line-height: 15px;
  color: #464646;
  margin-bottom: 10px;
}

.text,
.bgTitle {
  font-size: 12px;
  display: block;
}

.subtitle {
  font-size: 15px;
}

.bgContent {
  height: 72px;
  width: 100%;
  max-width: calc(100% - 130px);
  margin-left: 20px;
  background-size: contain !important;
}

.imageContent {
  display: flex;
}

.backgroundWrapper {
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
  width: 100%;
}
