@import 'mixins.scss';

.root {
  @include root-container;

  .content {
    @include root-content;
    width: calc(100% - 20px);
    height: calc(100% - 20px);

    padding-left: 40px;
    box-sizing: border-box;
    .head {
      width: 100%;
    }

    .main {
      display: flex;

      .name {
        font-weight: 700;
        font-size: 16px;
        margin: 10px 20px;
      }
    }
    .imageContent {
      position: relative;
      width: 168px;
      height: 168px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;

      .photoContainer {
        width: 30px;
        height: 30px;
        background: #000;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        transition: all 0.4s ease;
        border: 1px solid white;
        box-sizing: border-box;
        bottom: 10px;
        right: 10px;
        opacity: 0.8;

        .icon {
          fill: white;
          width: 18px;
          height: 18px;
        }

        &.hidden {
          opacity: 0;
          transition: all 0.4s ease;
        }
      }
    }
  }
}

.nameContainer {
  display: flex;
  align-items: center;

  .nameInput {
    border: none;
    font-weight: 700;
    font-size: 16px;
    margin-left: 10px;
    font-family: 'Montserrat', sans-serif;
    border-bottom: 2px solid grey;
    outline: none;
    margin: 10px 20px;
    box-sizing: border-box;
  }

  .editIcon {
    cursor: pointer;
    margin-left: 10px;
    margin-bottom: 1px;
  }

  .nameBtn {
    border: 2px solid grey;
    background: inherit;
    color: #000;
    cursor: pointer;
    height: 27px;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    font-weight: 700;

    &:disabled {
      cursor: not-allowed;
      background: grey;
      color: white;
    }
  }
}

.passBtn {
  margin: 10px 14px;
  padding: 4px 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  row-gap: 4px;
  column-gap: 14px;
  border: $message_border;
  border-radius: 8px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
  color: $gray_two;
  transition: all 0.5s ease-in;

  &:hover {
    color: $gray_one;
    border: 1px solid $gray_two;

    .passIcon {
      fill: $gray_two;
      transition: all 0.5s ease-in;
    }
  }
  .passIcon {
    fill: #fff;
    transition: all 0.5s ease-in;
  }
}

.popupContent {
  height: 150px;

  .popupHeader {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: 20px;
  }
}

.password {
  display: flex;
  align-items: center;
  .input {
    border: none;
    height: 40px;
    outline: none;
  }

  .icon {
    cursor: pointer;
  }
}

.deleteContainer {
  width: 30px;
  height: 30px;
  background: #000;
  border-radius: 10px;
  position: absolute;
  transition: all 0.4s ease;
  border: 1px solid white;
  box-sizing: border-box;
  top: 10px;
  right: 10px;
  opacity: 0.8;
  display: flex;
  align-items: center;
  justify-content: center;
  .deleteIcon {
    fill: #fff;
  }

  &.hidden {
    display: none;
  }
}

@media screen and (max-width: 590px) {
  .root {
    .content {
      .main {
        flex-direction: column;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .root {
    .content {
      .main {
        .name {
          font-size: 14px;
        }
      }
    }
  }
}
