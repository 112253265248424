@import 'variables.scss';

.root {
  width: 1040px;
  height: 920px;
  min-height: 100vh;
  background: $bg_image_login;
  background-size: cover;
  display: flex;
  align-items: flex-end;

  .wrapper {
    margin: 0px 0px 40px 40px;

    .head {
      width: 509px;
      height: 32px;
      margin-bottom: 18px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 40px;
      color: $white;
    }

    .subHead {
      width: 380px;
      height: 36px;
      margin-bottom: 46px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: #c6cad8;
    }

    .btn {
      width: 320px;
      height: 45px;
      background: $white;
      border-radius: 15px;
      border: none;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      color: $black;
    }
  }
}
