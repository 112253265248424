@import 'mixins.scss';

.root {
  .head {
    @include table-head;
  }
}

.charts {
  &.hidden {
    display: none;
  }
}

.loader {
  height: 400px;
  width: 100%;
  padding-left: 40px;
  display: flex;
  align-items: center;
  box-sizing: border-box;

  &.hidden {
    display: none;
    height: 0;
  }
}

.select {
  margin-right: 10px;
}
