@import 'variables.scss';

.root {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 20px;

  .btn,
  .btnUpdate {
    padding: 4px 7px;
    color: $player_black;
    background: $gray_three;
    border-radius: 5px;
    border: none;
    outline: none;
    cursor: pointer;

    &.selected {
      background: $player_black;
      color: $gray_three;
    }
  }

  .btnUpdate {
    padding: 0 5px;
    height: 24px;

    .icon {
      width: 20px;
      fill: $player_black;
    }
  }
}
