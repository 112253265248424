@import 'mixins.scss';

.root {
  margin-top: 2px;
  margin-bottom: 2px;
  position: relative;
}

.job {
  margin: 5px 0;

  &:hover {
    transition: border 0.5s ease;
    border: 2px solid #cfcece !important;
  }
  &.open {
    background: #fff;
  }

  &.closed {
    background: rgba(150, 150, 150, 0.5);
  }

  &.deleted {
    background: #ebf7f9;
  }

  &.archived {
    background: rgba(245, 234, 139, 0.4);
  }

  &.disabledWithLimit {
    background: #a7a7a717;
  }

  &.limit {
    background: seashell;
  }
}

.head {
  display: flex;
  align-items: center;

  .img {
    width: 30px;
    max-width: 30px;
    height: auto;
    max-height: 30px;
  }
}

.btnApply {
  position: absolute;
  display: block;
  top: 30px;
  right: 102px;
  width: auto;
  height: auto;
  font-size: 11px;
  padding: 3px 3px 6px 3px;
  font-weight: normal;
  border-radius: 4px;
  color: #fff;
  border: none;
  background: #ff2845;
  cursor: pointer;
}
