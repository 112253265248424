.image {
  width: auto;
  max-height: 24px;
  max-height: 50px;
  // image-rendering: pixelated;
  transition: all 0.8s ease;

  &.avatar {
    max-width: 168px;
    max-height: 168px;
    border-radius: 15px;
    border-radius: 50%;
    width: 100%;
    height: 100%;
  }

  &.hidden {
    display: none;
  }
}

.defaultImage {
  width: 32px !important;
  height: 32px !important;
  transition: all 0.8s ease;
  fill: #b9babb !important;

  &.default {
    margin-top: 3px;
  }

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }

  &.show {
    display: block;
  }

  &.isProfile {
    width: 168px !important;
    height: 168px !important;
    fill: #eef2f4 !important;
    border-radius: 50%;
  }
}
