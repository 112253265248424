@import '../../variables.scss';

.statistic-container {
  width: 100%;
  // min-height: 120px;
  padding: 20px 0;
  background: $white;
  border-radius: $border_radius;
}

.statistic-stats {
  width: 100%;
  flex-wrap: wrap;
  row-gap: 40px;
}

.stat-detail {
  &-container {
    min-width: 150px;
    height: 100%;
    background: inherit;
  }

  &-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: $gray_two;
    text-align: left;
  }

  &-info {
    margin-top: 9px;
  }

  &-image {
    width: 32px;
    height: 32px;
    display: block;
    margin-right: 17px;
  }

  &-with {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    display: flex;
    align-items: center;
  }

  &-first {
    color: $gray_two;
  }

  &-second,
  &-suffix {
    color: rgba(52, 59, 76, 0.747);
  }

  &-suffix {
    margin-left: 5px;
    margin-right: 5px;
  }

  &-subnum {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: $gray_two;
  }

  &-sub {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: $gray_one;
  }

  &-progress {
    margin-left: 60px;
  }
}

.progress {
  &-title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: $gray_five;
    margin-bottom: 10px;
  }

  &-subtitle {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: $gray_one;
  }

  &-content {
    max-width: 400px;
    margin-left: 32px;
  }
}

@media screen and (max-width: 1360px) {
  .progress-content {
    max-width: 290px;
  }

  .stat-detail-container {
    width: 170px;
  }
}

@media screen and (max-width: 1060px) {
  .stat-detail-container {
    width: 150px;
  }
}

@media screen and (max-width: 860px) {
  .progress-content {
    max-width: 173px;
  }

  .statistic-progress {
    width: 100%;
    justify-content: center;
  }

  .progress-content {
    max-width: 329px;
  }

  .statistic-container {
    flex-wrap: wrap;
    // height: 390px;
  }

  .statistic-stats {
    width: 100%;
    justify-content: space-around;
  }
}