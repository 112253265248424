.root {
  width: 256px;
  height: 507px;
  background: #ffffff;
  padding: 32px;
  // box-shadow: 0px 4px 6px rgba(12, 36, 71, 0.07);
  border: 2px solid rgba(12, 36, 71, 0.07);
  border-radius: 17px;
  user-select: none;
  position: relative;

  .header {
    display: flex;
    align-items: center;

    .icon {
      background: rgba(228, 232, 236, 0.5);
      border-radius: 15px;
      margin-right: 16px;
      width: 76px;
      height: 76px;
      min-width: 76px;
      min-height: 76px;
    }

    .topContainer {
      display: flex;
      flex-direction: column;

      .title {
        font-weight: 600;
        font-size: 31px;
        line-height: 31px;
        // color: #0c2447;
        color: #333333;
        margin-top: 0;
        margin-bottom: 9px;
      }

      .priceContainer {
        display: flex;
        align-items: center;

        .dollar {
          margin-right: 4px;
          font-weight: 500;
          font-size: 24px;
          line-height: 36px;
          text-align: center;
          color: #0c2447;
          opacity: 0.3;
          bottom: 3px;
          position: relative;
        }

        .price {
          font-weight: 600;
          font-size: 30px;
          line-height: 48px;
          color: #333333;
          margin-right: 8px;
        }

        .period {
          font-weight: 500;
          font-size: 16px;
          line-height: 27px;
          color: #8d96b2;
          position: relative;
          top: 4px;
        }
      }
    }
  }
}

.songsContainer {
  display: flex;
  align-items: center;
  margin-top: 12px;

  .musConatiner,
  .storageContainer {
    display: flex;
    align-items: center;
    width: 50%;
  }
}

.songsCount,
.storageCount,
.gb {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #0c2447;
}

.songsCount {
  margin-left: 12px;
}

.storageCount {
  margin-left: 10px;
  margin-right: 2px;
}

.line {
  margin-top: 23px;
  width: 100%;
  height: 1px;
  background: rgba(228, 232, 236, 0.5);
  border-radius: 15px;
}

.options {
  margin-top: 24px;
  max-height: 240px;
  overflow-y: auto;
}

.optionContainer {
  display: flex;
  align-items: center;
  margin-top: 16px;
}

.option {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #0c2447;
  opacity: 0.8;
  margin-left: 16px;
}

.btn {
  background: #3983f2;
  border-radius: 10px;
  height: 56px;
  width: 100%;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  cursor: pointer;
  transition: all 1s ease;
  opacity: 1;
  position: absolute;
  max-width: 256px;
  bottom: 32px;

  &:hover {
    opacity: 0.9;
    transition: all 1s ease;
  }

  &.selected {
    background: #f2f6f9;
    color: #6f809a;
    cursor: not-allowed;
  }
}

.product {
  margin-left: 5px;
}

.checkIcon {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.checkbox {
  display: flex;
  align-items: center;

  .labelCbx {
    height: 42px;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #686868;

    &.selected {
      font-weight: bold;
    }
  }
}