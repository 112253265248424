@import '../../variables.scss';

.musicianContent {
  width: 100%;
}

.infoContainerMus {
  width: calc(100% - 25px);
  box-shadow: 2px 1px 11px 3px rgba(181, 181, 181, 0.4);
  border-radius: $border-radius;
}

.root {
  background: #fff;
  border-radius: $border_radius;
  margin: 10px;
  padding: 20px;
  box-shadow: 2px 1px 11px 3px rgba(181, 181, 181, 0.4);
  box-sizing: border-box;
  height: calc(100vh - 190px);
  overflow: auto;
}

.container {
  display: flex;
  margin-top: 51.09px;
}

.masteringChannel {
  width: 100%;
}

.iframeWrapper {
  width: 100%;
  max-width: 800px;
  overflow: hidden;
  border-radius: 10px;
  margin: 0 auto;
}

.iframeEmbed {
  width: 100%;
  height: 300px;
  border: none;
  border-radius: 10px;
  background: transparent;
}

// Skeleton Loader Styling
.skeletonLoader {
  width: 100%;
  height: 300px;
  background: linear-gradient(90deg, #f3f3f3 25%, #ecebeb 50%, #f3f3f3 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite linear;
  border-radius: 10px;
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }

  100% {
    background-position: 200% 0;
  }
}

.hidden {
  display: none;
}

.infoBox {
  margin-top: 1rem;
  text-align: center;
}

.infoText {
  font-size: 1rem;
  line-height: 24px;
}

.infoLink {
  font-weight: bold;
  color: #007bff;
  text-decoration: none;
  transition: color 0.3s;
  margin-left: 5px;

  &:hover {
    color: #0056b3;
  }
}