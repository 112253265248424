@import 'variables.scss';

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 0px;
  border-radius: 15px;
  min-height: 250px;
  height: calc(100vh - 240px);
  overflow: hidden;
  background: $white;
  position: relative;
  overflow: auto;

  .wrapper {
    display: flex;
    flex-direction: column;
    height: 250px;
    overflow: auto;
  }
}

.loaderContainer {
  position: absolute;
  left: calc(50% - 20px);
  top: calc(50% - 20px);
  z-index: 100;
}
