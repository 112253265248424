.root {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: no-repeat url(../../assets/img/log-min.jpg);
  background-size: cover;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.main {
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 16px;
}

.link {
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}
