@import 'variables.scss';

.main,
.mainFullWidth {
  display: flex;
  flex-direction: column;
  width: calc(100% - 80px);
  height: 100vh;
  overflow: hidden;
  background: $site_bg;
  padding: 0;
}

.mainFullWidth {
  width: 100%;
}

.appContent {
  display: flex;
  overflow: hidden;
  max-height: 100vh;
}
