@import 'variables.scss';

.content {
  display: flex;
  align-items: center;
}
.btn {
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  background: inherit;
  border: none;
  outline: none;
  cursor: pointer;
  border: 2px solid transparent;
  transition: all 0.4s ease;
  border-radius: 4px;
  height: 20px;

  &:disabled {
    .icon {
      fill: #808588;
      transition: all 0.6s ease;
    }
    border: 2px solid #c5c4c4;
    cursor: not-allowed;
  }

  &:hover {
    opacity: 0.8;
    border: 2px solid #c5c4c4;
    .icon {
      fill: #278cc7;
      transition: all 0.6s ease;
    }
  }

  .icon {
    fill: $gray_two;
    transition: all 0.4s ease;
    width: 18px;
  }

  .text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 32px;
    color: $gray_two;
    user-select: none;
    margin-right: 4px;
  }
}
