@import 'variables.scss';

.root {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;

  .btns {
    display: flex;
    align-items: center;
    margin-left: 22px;
  }

  .downloadBtn {
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    background: inherit;
    border: none;
    outline: none;
    cursor: pointer;
    border: 2px solid transparent;
    transition: all 0.4s ease;
    border-radius: 4px;
    height: 36px;

    &:disabled {
      .icon {
        fill: #808588;
        transition: all 0.6s ease;
      }
      border: 2px solid #c5c4c4;
      cursor: not-allowed;
    }

    &.selected {
      background: #278cc7;
      color: white;
      .icon {
        fill: white;
        transition: all 0.6s ease;
      }

      .text {
        color: white;
      }

      &:hover {
        opacity: 0.8;
        border: 2px solid #278cc7;
        .icon {
          fill: white;
          transition: all 0.6s ease;
        }
      }
    }

    &:hover {
      opacity: 0.8;
      border: 2px solid #c5c4c4;
      .icon {
        fill: #278cc7;
        transition: all 0.6s ease;
      }
    }

    .icon {
      fill: $gray_two;
      transition: all 0.4s ease;
      width: 18px;
    }

    .text {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 32px;
      color: $gray_two;
      user-select: none;
      margin-right: 4px;
    }
  }
}

.downloadingText {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 32px;
  color: #333333;
  -webkit-user-select: none;
  user-select: none;
  margin-left: 7px;
}
