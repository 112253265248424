@import 'mixins.scss';

.tabs {
  display: flex;
}
.btn {
  @include headerTab;
  background: inherit;
  transition: all 1s ease;

  &.selected {
    @include headerTab;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}
