.selected {
  path {
    color: green;
  }
}

.notSelected {
  path {
    color: #4646;
  }
}

.container {
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
