@import 'variables.scss';

.company {
  display: grid;
  grid-template-columns: 50px 0.8fr 2fr 0.4fr 1fr;
  align-items: center;
  border-bottom: 1px solid $gray_fourth;
  border-top: 1px solid $gray_fourth;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  overflow: hidden;
  box-sizing: border-box;

  .name,
  .description,
  .owner,
  .members {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    color: $gray_two;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    white-space: break-spaces;
  }

  .name {
    margin-left: 5px;
  }

  .description {
    color: $gray_one;
    font-weight: normal;
  }

  .owner {
    color: $gray_one;
    font-weight: normal;
    line-height: 1;
  }

  .members {
    color: $gray_one;
    font-weight: normal;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .memberItem {
      cursor: pointer;
    }
  }
}

.btn,
.countBtn {
  background: inherit;
  width: 30px;
  height: 40px;
  border: none;
  cursor: pointer;
  transition: all 1s ease;

  &:hover {
    opacity: 0.6;
  }
}

.select {
  margin-bottom: 20px;
}

.label {
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: $gray_two;
  margin-top: 10px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fakePlaceholder {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  color: $gray_five;
}

.buttons {
  display: flex;
  align-items: center;
  width: 70px;
  margin-right: 27px;
  margin-left: 50px;
  cursor: pointer;
}

.countBtn {
  margin-right: 8px;
}
.button {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  width: 20px !important;
}

.avatarContainer {
  width: 50px;
  height: 50px;
  max-width: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $gray_fourth;
  border-radius: 7px;
  box-sizing: border-box;
  position: relative;

  &.iconInside {
    background: transparent;
  }

  .avatar {
    max-width: 50px;
    max-height: 50px;
    height: 100%;
    width: 100%;
  }
}

.verified {
  fill: #3f51b5 !important;
  position: absolute;
  bottom: -10px;
  right: -9px;
  font-size: 21px !important;
}
