@import '../../variables.scss';
@import '../../mixins.scss';

.root {
  position: relative;
  height: calc(100% - 55px);

  .tracks {
    height: calc(100% - 45px);
    overflow: auto;
    padding-top: 20px;
    box-sizing: border-box;
    position: relative;

    @include scrollbar;

    .loading {
      position: absolute;
      top: calc(50% - 20px);
      left: calc(50% - 20px);
    }
  }
}

.buttons {
  display: flex;
  align-items: center;
  height: 30px;
  position: absolute;
  bottom: 10px;
  right: 0px;
  row-gap: 10px;
  column-gap: 10px;
}

.saveBtn,
.clearBtn,
.cancelBtn {
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  background: #57a4ff;
  padding: 4px 11px;

  border-radius: 4px;
  opacity: 1;
  transition: all 0.5s ease;
  &:hover {
    transition: all 0.5s ease;
    background: #277adf;

    opacity: 0.8;
  }
}

.emptyMessage {
  margin-top: 50px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: $gray_one;
}

.infinityWrapper {
  max-height: auto !important;
}
