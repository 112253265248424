.root {
  min-width: 150px;
  height: 70px;
  color: grey;
  font-size: 12px;
  display: flex;
  align-items: center;
  border: 1px dashed darkgray;
  border-radius: 15px;
  padding: 0 5px;
  background: #eef2f4;
  overflow: hidden;
  text-overflow: ellipsis;

  p {
    width: 100%;
    max-width: 800px;
    overflow: hidden;
    word-break: break-all;
    box-sizing: border-box;
    text-overflow: ellipsis;
  }
}

.disabled {
  cursor: not-allowed;
}
