@import 'mixins.scss';

.root {
  position: relative;
  height: 180px;
  padding: 10px 0 0 0;
  box-sizing: border-box;
}

.emptyContent {
  width: 100%;
  height: 154px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  @include text;
}

.input {
  width: calc(100% - 105px) !important;
}
.createBtn {
  position: absolute;
  height: 30px;
  border: none;
  background: #343b4c;
  border-radius: 15px;
  color: #ffffff;
  transition: all 1s ease;
  font-weight: normal;
  font-size: 13px;
  cursor: pointer;
  bottom: 7px;
  padding: 2px 7px;
  right: 8px;
  line-height: 25px;
  opacity: 1;
  box-sizing: border-box;
}

.links {
  overflow: auto;
  height: 139px !important;
  width: 99%;
}
