@import 'variables.scss';
@import 'mixins.scss';

.root {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-bottom: 15px;

  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    color: $gray_one;
  }

  .textarea {
    font-family: 'Montserrat', sans-serif;
    width: 100%;
    border: none;
    border-bottom: 2px solid #c6cad8;
    outline: none;
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    color: #333333;
    background: inherit;
    border-radius: 0;
    resize: none;
    max-height: 100px;
    overflow: auto;
    @include scrollbar;

    &:focus {
      border-bottom: 2px solid $gray_two;
    }

    &.errorText {
      border-bottom: 2px solid red;
    }
  }

  .invisible {
    opacity: 0;
    transition: all 0.4s linear;
  }

  .error {
    opacity: 1;
    font-size: 10px;
    color: red;
    margin-left: 10px;
  }
}
