@import 'variables.scss';

.root {
  display: flex;
  align-items: center;
  color: $gray_two;
  font-size: 12px;
  line-height: 18px;

  .value {
    font-weight: bold;
    margin-left: 9px;
  }
}
