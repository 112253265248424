@import 'variables.scss';

.root {
  display: flex;
  margin-right: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #464646;

  .title {
    color: $gray_two;
    text-align: center;
  }

  .count {
    color: $gray_two;
    font-weight: 600;
    font-size: 14px;
    margin-left: 4px;
  }
}
