.root {
  margin-bottom: 20px;
  .title {
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #333333;
    margin-top: 10px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
