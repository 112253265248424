.settingsBtn {
  margin-left: 19px !important;

  .settings {
    fill: #464646;
  }
}

.first500Badge {
  width: 57px;
  margin-right: 10px;
}
.trialCountdown {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 12px;
  font-weight: bold;
  font-size: 14px;
  color: #fff;
  transition: background-color 0.3s;
  width: 200px; /* Adjust width as per your design */
  text-align: center; /* Center text within the box */
}

.active {
  background-color: rgb(144, 238, 144); /* Light green with opacity */
}

.expired {
  background-color: rgba(255, 99, 71, 0.8); /* Light red with opacity */
}

.button {
  background-color: #e23d1d;
  border-radius: 10px;
  padding: 10px 20px;
  border: 0;
  outline: 0;
  color: #fff;
  width: 150px;
  font-weight: 700;

  cursor: pointer;

  :hover {
    border: #dd3513;
  }
}
