@import 'variables.scss';
@import 'mixins.scss';

.redirectPage {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: $white;
  color: #343b4c;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: absolute;
  z-index: 10000;
  background: $white;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 489px;
  height: auto;
  padding: 40px 10px 20px 10px;
  border-radius: 15px;
  position: relative;
  background: rgba(255, 255, 255, 0.815);
  box-shadow: 2px -1px 10px -2px rgba(0, 0, 0, 0.75);
  box-sizing: border-box;
  margin: 20px auto;

  .title {
    font-size: 18px;
    text-align: center;
    align-self: normal;
    margin-bottom: 20px;
    margin-top: 0;
    user-select: none;
  }

  .input {
    max-width: 400px;
    width: 100%;
    font-size: 15px;
    margin-bottom: 5px;
  }

  .select {
    margin-bottom: 10px;
    font-size: 15px;
    font-weight: 600;
  }

  .select span[role='option'] {
    font-size: 15px;
    font-weight: 600;
  }
}


.text {
  @include text;
  margin-bottom: 10px;
  align-self: normal;
  margin-left: 59px;
}

.backButton {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #eef2f4d3;
  border-radius: 4px;
  padding: 2px 4px;
  transition: all 1s ease;
  position: absolute;
  top: 30px;
  left: 20px;

  &:hover {
    .icon {
      opacity: 0.6;
    }
  }

  .icon {
    font-size: 20px;
    opacity: 1;
    margin-left: 3px;
  }

  .textBack {
    color: $gray_one;
    font-weight: bold;
  }
}

.selectContainer {
  background: #f9fafb;
  margin: 0px 0px 18px 0px;
  border: 2px solid #eef2f4;
  border-radius: 15px;
}

.powered {
  max-width: 483px;
  width: 100%;
}

.btn {
  max-width: 400px;
  width: 100%;
}

.alreadyMember {
  margin-top: 20px;
}

@media screen and (max-width: 480px) {
  .form {
    max-width: 100%;
    width: calc(100% - 10px);
    margin: 0 5px;
  }

  .text {
    margin-left: 0;
    text-align: center;
  }
}

@media screen and (max-height: 1000px) {
  .main {
    overflow-y: auto;
    height: 100%;
    overflow-x: hidden;
  }
}