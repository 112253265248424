@import 'variables.scss';

@mixin table-head {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: $gray_two;
}

@mixin table-column-name {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: $gray_one;
}

@mixin text {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #464646;
}

@mixin table-big-text-without-width {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: $gray_one;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

@mixin margin-left-variant($pix) {
  margin-left: $pix !important;
}

@mixin margin-right-variant($pix) {
  margin-right: $pix !important;
}

@mixin margin-top-variant($pix) {
  margin-top: $pix !important;
}

@mixin margin-bottom-variant($pix) {
  margin-bottom: $pix !important;
}

@mixin margin-variant($pix) {
  margin: $pix !important;
}

@mixin padding-variant($pix) {
  padding: $pix !important;
}

@mixin padding-left-variant($pix) {
  padding-left: $pix !important;
}

@mixin padding-right-variant($pix) {
  padding-right: $pix !important;
}

@mixin padding-top-variant($pix) {
  padding-top: $pix !important;
}

@mixin padding-bottom-variant($pix) {
  padding-bottom: $pix !important;
}

@mixin root-container() {
  background: #fafafa;
  display: flex;
  justify-content: center;
  height: 100%;
}

@mixin root-content() {
  width: calc(100% - 60px);
  height: calc(100% - 60px);
  min-height: 300px;
  padding: 20px;
  background: #fff;
  border-radius: 15px;
  overflow: auto;
  box-shadow: 2px 1px 11px 3px rgba(181, 181, 181, 0.4);
}

@mixin headerTab {
  background: #eef2f4;
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  text-transform: uppercase;
  color: #464646;
  cursor: pointer;
  outline: none;
  margin: 0px 10px;
  border-radius: 6px;
}

@mixin btnPrimary {
  width: 320px;
  height: 45px;
  background: #343b4c;
  border-radius: 15px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  border: none;
  text-align: center;
  cursor: pointer;
  color: $white;
  user-select: none;

  &:disabled {
    cursor: not-allowed;
    background: #565c69;
  }
}

@mixin text-overflow {
  text-overflow: ellipsis;
  word-break: break-all;
  overflow: hidden;
  white-space: nowrap;
}

@mixin scrollbar {
  &::-webkit-scrollbar-thumb {
    background-color: #cecece;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    border-top-right-radius: 22px;
    border-bottom-right-radius: 6px;
    overflow: hidden;
    margin-top: 0px;
  }

  &::-webkit-scrollbar {
    background: #f1f1f1;
    width: 7px;
    border-bottom-right-radius: 6px;
  }
}
