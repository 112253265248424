@import '../../variables.scss';

.uploadContent {
  background: $white;
  width: calc(100vw - 160px);
  height: 100%;

  .subcontent {
    // width: 100%;
    position: relative;
    height: 100%;
    max-height: calc(100vh - 217px);
    overflow: auto;

    .line {
      width: 100%;
      height: 1px;
      background: transparent;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      height: 37px;
      right: 0px;
      width: 100%;
      bottom: 9px;
    }
  }
}

.infoWrapper {
  display: flex;
  flex-direction: column;

  .copyInfoFromSavedTrack {
    padding-left: 11px;
    box-sizing: border-box;
    margin-bottom: 10px;

    .fakePlaceholder {
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      line-height: 16px;
      color: $gray_five;
      position: relative;
      top: 2px;
      left: 0px;
    }

    .copySelect {
      width: 400px;
      max-width: 400px;
    }
  }

  .content {
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    .selectContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 64px;

      .select {
        margin: 0 10px;

        label {
          color: $gray_one;
          font-size: 14px;
          font-family: 'Montserrat', sans-serif;
        }

        select {
          padding-left: 10px;
        }
      }
    }
  }

  .line {
    width: 100%;
    height: 1px;
    background: transparent;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .writers,
  .publishers {
    .title {
      font-size: 14px;
      font-weight: bold;
    }
  }

  .publishers {
    padding-bottom: 30px;
    box-sizing: border-box;
  }

  .cont {
    display: flex;
  }
}

.itemInput,
.itemInputError {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 3px 10px 0 10px;
  position: relative;
  height: 60px;

  input {
    font-size: 12px;
  }

  .errorText {
    display: none;
  }
}

.itemInputError {
  input {
    &:focus {
      border-bottom: 2px solid rgb(231, 43, 43);
    }

    border-bottom: 2px solid rgb(196, 52, 52);
  }

  .errorText {
    display: block;
    position: absolute;
    bottom: -14px;
    font-size: 11px;
    color: red;
    font-weight: bold;
    width: 224px;
  }
}

.btn {
  background-color: transparent;
  position: absolute;
  top: 0;
  right: 0;
  outline: none;
  border: none;
}

.closebtn {
  position: absolute;
  top: 0px;
  right: 0px;
}

.numericInput {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  color: #333333 !important;
  border: none !important;
  border-bottom: 2px solid #c6cad8 !important;
  background: inherit !important;
  outline: none !important;
  padding: 3px 5px !important;
  width: 100px !important;
}

.wrap {
  height: 100px;
  overflow: auto;
  background: #fdfdfd;
}

@media screen and (max-width: 1150px) {
  .wrap {
    height: 170px;
  }
}
