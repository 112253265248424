@import '../../variables.scss';

.manager-track {
  &-container {
    border-top: 1px solid #eef2f494;
    border-bottom: 1px solid #eef2f494;
    min-height: 47px;
    cursor: pointer;
    user-select: none;
    font-size: 11px;

    &:first-of-type {
      border-top: 2px solid #eef2f494;
    }

    &:last-of-type {
      border-bottom: 2px solid #eef2f494;
    }

    &-n {
      background: $white;
    }

    &-s {
      background: $gray_fourth !important;
    }
  }

  &-selector {
    width: 20px;
    margin-right: 2px;
    padding: 10px;
  }

  &-sel {
    width: 98px;
  }

  &-select {
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    color: $gray_two;
    opacity: 0.6;
  }

  &-not-sel {
    width: 98px;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    color: $gray_two;
  }

  &-title {
    text-overflow: ellipsis;
    overflow: hidden;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    color: $gray_two;
  }

  &-duration {
    text-overflow: ellipsis;
    overflow: hidden;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    color: $gray_one;
  }

  &-author {
    text-overflow: ellipsis;
    overflow: hidden;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    color: $gray_two;
  }

  &-rating {
    text-overflow: ellipsis;
    overflow: hidden;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    color: $gray_two;
    display: flex;
    align-items: center;
  }

  &-download {
    width: 39px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 16px;
      height: 16px;
    }
  }
}

.icon-track {
  width: 16px;
  height: 11px;
  cursor: pointer;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }

  &-n {
    width: 14px;
    height: 14px;
    cursor: pointer;
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
  }
}

.manager-tr-t {
  overflow: hidden;
  text-overflow: ellipsis;
  width: fit-content;
}

.btn-new-playlist {
  background: inherit;
  border: none;
  font-size: 22px;
  cursor: pointer;
  color: $gray_one;
}

.new-playlist-container {
  margin-bottom: 10px;
  height: 200px;
}

.new-playlist-input {
  height: 30px;
  border-radius: 15px;
  border: 1px solid $gray_one;
  padding-left: 10px;
  padding-right: 10px;
  outline: none;
}

.track-subscription {
  display: flex;
  align-items: center;
  margin-left: 7px;
}

.track-subscription-icon {
  height: 20px;
  max-height: 20px;
}

.manager-track-play {
  width: 13px;
}

.icon-track-n-wrapper {
  width: 40px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.track-was-listened {
  background: rgba(86, 160, 223, 0.19);
  border-bottom: 1px solid #049bff4d;
  border-top: 1px solid #049bff4d;

  &:first-of-type {
    border-top: 2px solid #049bff4d;
  }

  &:last-of-type {
    border-bottom: 2px solid #049bff4d;
  }
}

.manager-track-container {
  display: grid;
  align-items: center;
  grid-template-columns: 50px 40px 2fr 100px 1.5fr 1fr;
}

.is_first_500 {
  height: 20px;
  &_badge {
    height: 20px;
    margin-left: 5px;
  }
}
