.root {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  box-sizing: border-box;
  width: 100%;
  border: 2px solid transparent;
  border-radius: 15px;
  row-gap: 10px;
  column-gap: 10px;
  font-size: 13px;

  &:hover {
    transition: all 0.5s ease;
    border: 2px solid #cecece38;

    .action,
    .deleteBtn {
      display: flex;
    }
  }

  .column {
    width: 100px;
    display: flex;
    align-items: center;
    font-size: 12px;
  }
}

.visaIcon {
  width: 40px;
  height: auto;
}

.defaultIcon {
  svg {
    fill: greenyellow;
    color: red;
    background: red;
  }
}

.action {
  display: none;
  align-items: center;
  column-gap: 6px;
  font-size: 11px;
  cursor: pointer;
  background: #343b4c;
  border-radius: 15px;
  color: #fff;
  padding: 5px;
}

.deleteBtn {
  display: none;
}

.popupText {
  font-size: 12px;
  width: 100%;
  text-align-last: left;
  line-height: 17px;
}
