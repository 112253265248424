@import '../../variables.scss';

.mus-track-item {
  &-container {
    width: calc(100% - 20px);
    height: calc(100vh - 240px);
    min-height: 300px;
    background: $white;
    border-radius: $border_radius;
    overflow: hidden;
    margin-bottom: 20px;
    align-self: center;
    box-shadow: 2px 1px 11px 3px rgba(181, 181, 181, 0.4);
  }

  &-nav {
    padding: 16px 20px;
  }

  &-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #343b4c;
  }

  &-sub {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #8d96b2;
  }

  &-wrap {
    margin-top: 10px;
    height: 310px;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100% - 106px) !important;
    min-height: 310px !important;
  }
}

.mus-tracks-show {
  &-container {
    width: 1260px;
    height: 632px;
    background: $white;
    border-radius: $border_radius;
    z-index: 100;
    top: 115px;
    animation: open 300ms ease-in;
    animation-iteration-count: 1;
    overflow: hidden;

    @keyframes open {
      0% {
        height: 400px;
      }

      100% {
        height: 600px;
      }
    }
  }

  &-wrap {
    margin-top: 10px;
    width: 100%;
    height: 530px;
    overflow: auto;
  }
}

@media screen and (max-width: 1360px) {
  .mus-tracks-show-container {
    width: calc(100% - 100px);
  }
}
