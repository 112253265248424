.link {
  color: blue;
  text-decoration: underline;
  margin-left: 4px;
  display: inline;
  cursor: pointer;

  &:hover {
    color: rgb(69, 69, 206);
  }
}
