@import 'variables.scss';

.root {
  display: grid;
  grid-template-columns: 100px 1fr 1fr 0.5fr 1fr 2fr;
  width: 100%;
  background: white;
  color: $gray_one;
  font-size: 12px;
  border-bottom: 1px solid $gray_fourth;
  align-items: center;
  user-select: none;
  box-sizing: border-box;
  min-height: 70px;
  padding: 5px 0;

  .avatarWrap,
  .userName,
  .email,
  .isDisabled,
  .stat,
  .action,
  .rating {
    word-wrap: break-word;
    word-break: break-word;
    padding-right: 5px;
    box-sizing: border-box;
  }

  .action {
    height: 40px;
    display: flex;
    align-items: center;
  }

  .rating {
    display: flex;
    align-items: center;

    .icon {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }

    .text {
      margin-right: 5px;
      font-size: 14px;
    }
  }

  .avatarWrap {
    display: flex;
    align-items: center;
    justify-content: center;

    .image {
      max-width: 86px;
      height: auto;
      max-height: 70px;
      margin: 7px;
    }

    .defaultAvatar {
      width: 100px;
      height: auto;
      max-height: 50px;
      max-width: 50px;
      color: $gray_one;
    }
  }

  .stat {
    display: flex;

    .statWrap {
      padding: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: 1px solid $gray_fourth;
      margin: 2px;
      border-radius: 15px;
      padding: 4px;

      .title {
        font-weight: bold;
        border-bottom: 1px solid $gray_fourth;
        padding: 4px;
        .icon {
          width: 18px;
          height: 18px;
        }
      }
    }
  }
}

.rootAllUsers {
  grid-template-columns: 100px 1fr 1fr 0.5fr 1fr;
}

.first500Badge {
  height: 20px;
  margin-right: 5px;
}

.redBadge,
.greenBadge {
  padding: 4px 6px;
  border-radius: 15px;
  background: green;
  font-size: 11px;
  color: $white;
}

.redBadge {
  background: red;
}

.btn {
  background: inherit;
  border: none;
  border-radius: 50%;
  outline: none;
  cursor: pointer;

  svg {
    color: $gray_one;
  }
}

.greenBtn {
  svg {
    color: green;
  }
}

.redBtn {
  svg {
    color: red;
  }
}

.popup {
  display: flex;
  align-items: center;
  width: 550px;
  height: 120px;
  font-size: 14px;
  color: $gray_two;
  line-height: 20px;
}

.subscription {
  width: 120px !important;
}

.approveIcon,
.infoIcon {
  width: 24px;
  height: 24px;
  transition: all 0.5s ease;
}

.approveIcon.disabled,
.infoIcon.disabled {
  cursor: not-allowed;
  opacity: 0.8;
  transition: all 0.5s ease;
}

.infoIcon.disabled {
  opacity: 0.3;
}

.linkContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  .linkName {
    font-weight: bold;
    color: $gray_two;
    margin-right: 5px;
  }
}

.links {
  width: 100%;
  margin-bottom: 14px;
  font-size: 21px;
}

.popupLinks {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
}

.star {
  font-size: 18px;
}

.linkName {
  width: 70px;
}

.linkValue {
  max-width: calc(100% - 70px);
  overflow: hidden;
  text-overflow: ellipsis;
}

.created {
  margin-top: 5px;
  margin-bottom: 5px;
}

.top {
  display: flex;
  align-items: center;
}
