@import 'variables.scss';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: $site_bg;

  .info {
    display: flex;
    align-items: center;

    .newJobBtn {
      width: 210px;
      height: 32px;
      background: $white;
      border-radius: 15px;
      border: none;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      color: $gray_two;
      cursor: pointer;
      transition: all 1s ease;
      box-shadow: 0px 1px 5px 2px rgba(0, 0, 0, 0.1);
      &:hover {
        box-shadow: 0px 0px 2px 3px rgba(0, 0, 0, 0.2);
      }

      .btnContent {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.wrap {
  overflow: visible;
  height: 450px;
  .wrapItem {
    display: flex;
    align-items: center;
    justify-content: space-around;

    .header {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      color: $gray_two;
      margin-bottom: 20px;
      margin-top: 10px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .avatarContent {
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    .title {
      font-size: 17px;
      align-self: stretch;
      margin-bottom: 5px;
    }

    .text {
      margin: 0;
      padding: 5px;
      font-size: 11px;
      align-self: stretch;
      margin-bottom: 10px;
    }

    .avatarWrapper {
      height: calc(100% - 154px);
      display: flex;
      justify-content: center;
      align-items: center;
      .avatar {
        height: auto;
        width: 100px;
      }
    }
  }
}

.selectBuyer {
  width: 49%;
  margin-right: 10px;
}

.selectAccess {
  width: 49%;
  margin-left: 10px;
}

.fakePlaceholder,
.fakePlaceholder_two {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  color: $gray_five;
  position: relative;
  top: 10px;
}

.fakePlaceholder_two {
  margin-left: 10px;
}

.inviteList {
  width: calc(100% - 60px);
  height: calc(100% - 60px);
  padding: 20px;
  background: #fff;
  border-radius: 15px;
  overflow: auto;
  box-shadow: 2px 1px 11px 3px rgba(181, 181, 181, 0.4);

  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #333333;
  }
}

.controls {
  width: 100%;
  display: flex;
  align-items: center;
  .controlBtn {
    background: inherit;
    border: none;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    text-transform: uppercase;
    color: $gray_one;
    cursor: pointer;
    outline: none;
    margin: 0px 10px;
    border-radius: 15px;
    display: block;
  }

  .selectedBtn {
    background: $gray_fourth;
  }
}

.tableHeader {
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 10px;

  .userName,
  .companyName,
  .status {
    width: 150px;
    max-width: 150px;
    min-width: 150px;
    font-weight: normal;
    overflow: hidden;
    user-select: none;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: $gray_one;
    cursor: pointer;
  }

  .userName {
    width: 200px;
    max-width: 200px;
    min-width: 200px;
  }

  .controls {
    font-weight: normal;
    overflow: hidden;
    user-select: none;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: $gray_one;
    cursor: pointer;
  }
}

.table {
  display: flex;
  flex-direction: column;
  -webkit-user-select: none;
  user-select: none;
  height: calc(100% - 70px);
  overflow-y: auto;
  overflow-x: hidden;

  .tableItem {
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #eef2f4;
    border-top: 1px solid #eef2f4;
    padding-left: 10px;
    padding-right: 10px;
    overflow: hidden;

    .nameInvite,
    .companyInvite,
    .statusInvite {
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 22px;
      color: #333333;
      width: 150px;
      max-width: 150px;
      min-width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
      white-space: break-spaces;
    }

    .statusInvite {
      text-transform: capitalize;
    }

    .nameInvite {
      font-weight: bold;
      max-width: 200px;
      min-width: 200px;
      width: 200px;
    }
  }
}

.btn {
  background: inherit;
  width: 40px;
  height: 40px;
  border: none;
  cursor: pointer;
  transition: all 1s ease;
  margin-right: 10px;
  &:hover {
    opacity: 0.6;
  }
}

.inviteSelect {
  margin-bottom: 7px;
}

.label {
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: $gray_two;
  margin-top: 10px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: 1360px) {
  .company-info {
    width: calc(100% - 100px);
  }

  .root {
    .info {
      width: calc(100% - 100px);
    }
  }
}
