@import '../../variables.scss';

.root {
  width: 400px;
  height: 920px;
  min-height: 100vh;
  background: $white;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .forgot {
    text-align: center;
    display: block;
    margin-bottom: 40px;
  }
}
