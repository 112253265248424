@import 'mixins.scss';

.root {
  height: 100%;
}

.btn {
  @include headerTab;
  background: inherit;
  transition: all 1s ease;
  line-height: 1;
}

.dropzone {
  width: 370px;
  margin-left: 10px;
  margin-top: 12px;
  margin-bottom: 12px;
}

.search {
  width: 368px;
  margin-left: 12px;
  margin-bottom: 12px;
}

.body {
  height: calc(100% - 271px) !important;
  position: relative;
}

.dropzoneItem {
  height: 50px;
}

.loaderDropzone {
  width: 370px;
}

.loader {
  position: absolute;
  width: 100%;
  height: inherit;

  display: flex;
  align-items: center;
  justify-content: center;
}

.storageItem,
.storageItemUsed {
  font-weight: 500;
  font-size: 11px;
  line-height: 20px;
  color: #8d96b2;
  margin-left: 10px;
  margin-bottom: 10px;

  .bold,
  .boldRight {
    font-weight: bold;
    margin-right: 4px;
  }

  .boldRight {
    margin-left: 3px;
  }
}

.storageItem {
  margin-bottom: 5px;
}

.storageItemUsed {
  margin-top: 0;
}

.linkNotActive {
  font-weight: bold;
  color: red;
}

.notActive {
  margin-top: 10px;
}
