.link {
  display: flex;
  align-items: center;
  justify-content: center;

  .input {
    width: 100%;
    font-size: 11px;
  }
}
