@import 'mixins.scss';

.filter,
.jobTypeText {
  @include headerTab;
  background: inherit;
  transition: all 1s ease;
  font-size: 9px;
  padding: 0 4px;

  &.selected {
    @include headerTab;
    font-size: 9px;
    padding: 0 4px;
  }

  &.disabled {
    cursor: not-allowed;
  }
}

.root {
  min-height: 50px;
  display: flex;
  width: 100%;

  .searchContainer {
    border-bottom-left-radius: 15px;
    border-top-left-radius: 15px;
    background: #f9f9f9;
    border: 1px solid #e8e8e8;
    width: 50%;
    position: relative;
    transition: all 1s ease;
    min-height: 54px;

    &:focus {
      border: 1px solid #1d1d1d;
      transition: all 1s ease;
    }

    &:hover {
      background: #eef2f4;
      transition: all 1s ease;

      .searchIcon {
        fill: #2e2e2e;
      }
    }

    .searchIcon {
      position: absolute;
      left: 15px;
      top: calc(50% - 10px);
      cursor: pointer;
    }

    .input {
      top: 0;
      background: transparent;
      border: none;
      outline: none;
      margin-left: 10px;
      height: 100%;
      position: absolute;
      z-index: 1;
      left: 36px;
      font-size: 19px;
      font-weight: 400;
      width: calc(100% - 90px);
    }

    .clearIcon,
    .loader {
      position: absolute;
      right: 10px;
      top: calc(50% - 10px);
      cursor: pointer;
      fill: #959595;
      transition: all 0.4s ease;

      &:hover {
        fill: #2e2e2e;
      }
    }
    .loader {
      top: calc(50% - 5px);
      right: 14px;
    }
  }
}

.typesContainer {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  column-gap: 5px;
  row-gap: 2px;
  align-items: center;
  border-bottom-right-radius: 15px;
  border-top-right-radius: 15px;
  background: inherit;
  border: 1px solid #e8e8e8;
  transition: all 0.4s ease;
  position: relative;
  padding: 5px 33px 5px 0;
  border-left: none;

  &:hover {
    background: #f9f9f9;
    transition: all 0.4s ease;

    .filter,
    .jobTypeText {
      &.selected {
        background: #000;
        color: white;
      }
    }
  }

  .faqIcon {
    position: absolute;
    top: calc(50% - 10px);
    right: 10px;
    fill: #6b6b6b;
    width: 21px;
    height: 21px;
  }
}

@media screen and (max-width: 1020px) {
  .root {
    flex-direction: column;

    .searchContainer {
      width: 100%;
      border-bottom-left-radius: 0px;
      border-top-right-radius: 15px;
      box-sizing: border-box;
      .input {
        height: 45px;
      }
    }

    .typesContainer {
      width: 100%;
      box-sizing: border-box;
      border-top-right-radius: 0px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 15px;
      border: 1px solid #e8e8e8;
    }
  }

  .ощируфвук {
    width: 100%;
    border-bottom-left-radius: 15px;
    border-top-right-radius: 0px;
    padding: 5px 0;
    box-sizing: border-box;
    border-left: 1px solid #e8e8e8;
  }
}

.image {
  width: 70px;
  height: 25px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  margin: 0 10px;
  object-fit: cover;
  border-radius: 3px;

  &.selected {
    border-radius: 4px;
    filter: drop-shadow(2px 2px 4px grey);
    transition: all 0.5s ease-in-out;
  }

  &.disabled {
    transition: all 0.5s ease-in-out;
    cursor: not-allowed;
    filter: invert(1);
  }
}

.jobTypeText {
  @include headerTab;
  background: inherit;
  transition: all 1s ease;
  font-size: 9px;
  padding: 0 4px;

  &.selected {
    @include headerTab;
    font-size: 9px;
    padding: 0 4px;
  }
}

.loadingContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .loadingText {
    font-weight: 500;
    font-size: 11px;
    color: #8d96b2;
    margin-left: 10px;
  }
}
