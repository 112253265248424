.root {
  position: relative;
  width: calc(100% - 225px);
  display: flex;
  align-items: center;
  margin-left: 20px;

  &:hover {
    .icon {
      fill: #2e2e2e;
      transition: all 400ms linear;
    }
  }

  .input {
    width: 100%;
    border: 1px solid #ededed;
    border-radius: 15px;
    background: inherit;
    height: 36px;
    outline: none;
    transition: all 400ms linear;
    padding-left: 20px;
    box-sizing: border-box;

    &:focus {
      border: 1px solid #a5a5a5;
      transition: all 400ms linear;
    }
  }

  .icon {
    width: 20px;
    height: 15px;
    position: absolute;
    right: 10px;
    transition: all 400ms linear;
    fill: #8a8a8a;
  }
}
