@import 'mixins.scss';

.root {
  @include root-container;

  .content {
    @include root-content;
    display: flex;

    .leftSide {
      width: calc(100% - 380px);
    }

    .rightSide {
      width: 380px;
      height: 100%;
    }
  }
}