@import 'mixins.scss';

.root {
  width: calc(100% - 44px);
  display: flex;
  align-items: center;
  height: 40px;
  margin: 0px 10px;
  padding-left: 24px;

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 900px;
    column-gap: 10px;
    padding: 0 10px;
    box-sizing: border-box;

    &:hover {
      box-shadow: 2px 0px 9px -1px rgba(166, 166, 166, 0.88);
      -webkit-box-shadow: 2px 0px 9px -1px rgba(166, 166, 166, 0.88);
      -moz-box-shadow: 2px 0px 9px -1px rgba(166, 166, 166, 0.88);
    }

    .progress {
      font-style: normal;
      line-height: 18px;
      color: $gray_one;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      font-weight: 400;
      font-size: 11px;
      min-width: 40px;
      text-align-last: right;
    }

    .play,
    .downloadBtn {
      width: 40px;
      height: 40px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      outline: none;
      background: transparent;
      border: none;
      transition: all 0.6s ease;
      opacity: 1;
      cursor: pointer;

      &:hover {
        opacity: 0.8;

        .icon {
          fill: #278cc7;
          transition: all 0.6s ease;
        }
      }

      &:disabled {
        .icon {
          fill: #808588;
          transition: all 0.6s ease;
        }
        cursor: not-allowed;
      }

      .icon {
        transition: all 0.6s ease;
        fill: #333333;
        width: 24px;
        height: 24px;
      }
    }

    .textContainer {
      width: calc(100vw - 180px);
      font-family: Montserrat;
      font-style: normal;
      font-weight: 400;
      color: #333333;
      -webkit-user-select: none;
      user-select: none;
      overflow: hidden;
      text-overflow: ellipsis;
      .title {
        @include table-big-text-without-width;
        -webkit-line-clamp: 1;
        font-weight: 600;
        font-size: 13px;
        display: block;
      }
      .subTitle {
        @include table-big-text-without-width;
        -webkit-line-clamp: 1;
        font-weight: 400;
        font-size: 11px;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .root {
    width: 100%;
    margin: 0;
    padding: 0;
  }
}
