@import 'variables.scss';

.root {
  align-items: center;
  margin-top: 30px;
  border-bottom: 1px solid #eef2f4;
  display: grid;
  grid-template-columns: 3fr 1fr 2fr 1fr 100px;
  .headerIteam {
    user-select: none;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: $gray_one;
  }
}
