.logoContainer {
  width: 70px;
  user-select: none;
  .root {
    width: 70px;
    height: auto;
    margin-top: 10px;
    margin-bottom: 44px;
    margin-left: 7px;
    cursor: pointer;
  }
}
