@import 'variables.scss';
.root {
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  height: 100%;
}

.emptyTitle {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #464646;
}

.btn {
  height: 30px;
  border: none;
  background: #343b4c;
  border-radius: 15px;
  color: #ffffff;
  transition: all 1s ease;
  font-weight: normal;
  font-size: 13px;
  cursor: pointer;
  padding: 2px 7px;
  margin-bottom: 10px;
  margin-left: auto;
  display: block;
}

.unsaved {
  font-size: 11px;
  padding: 2px 4px;
  border-radius: 4px;
  margin-right: 7px;
  color: $gray_two;
  background: $gray_fourth;
  cursor: default;
  display: none;
  width: 47px;
  position: absolute;
  top: -21px;
  right: 7px;

  &.show {
    display: block;
  }
}
