@import 'variables.scss';
@import 'mixins.scss';

.root {
  width: 500px;
  min-height: 100px;
  padding-bottom: 20px;
  box-sizing: border-box;

  .publisherContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 12px;

    .title {
      font-weight: bold;
      font-style: italic;
      width: 100%;
      display: flex;
      align-items: center;
      height: 20px;
      padding-top: 5px;
      padding-left: 10px;
      box-sizing: border-box;
    }

    .header {
      display: flex;
      align-items: center;
      border-bottom: 1px solid $gray_five;
      font-weight: bold;
      width: 100%;
      height: 30px;
      padding-left: 20px;
      box-sizing: border-box;


      div {
        width: 100px;
      }
    }

    .content {
      border-bottom: 1px solid $gray_fourth;
      display: flex;
      align-items: center;
      height: 30px;
      width: 100%;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: $gray_one;
      padding-left: 20px;
      box-sizing: border-box;

      div {
        width: 100px;
        @include text-overflow;
      }
    }
  }
}