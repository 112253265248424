@import 'variables.scss';
.root {
  width: 100%;
  height: 300px;
  overflow: hidden;

  .container {
    display: flex;
    align-items: center;
    padding: 10px 0;
    box-sizing: border-box;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  .wrapper {
    height: 224px;
    overflow: hidden;
  }

  .name {
    width: 190px;
    margin-right: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .date {
    width: 150px;
  }

  .member {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    user-select: none;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    color: $gray_one;
    box-sizing: border-box;
    border-bottom: 1px solid #eef2f4;
  }

  .emptyMembers {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: $gray_one;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
