@import 'mixins.scss';

.root {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  margin-top: 20px;
  box-sizing: border-box;
  width: 98%;

  .item {
    @include table-column-name;

    &:nth-of-type(1) {
      width: 400px;
    }

    &:nth-of-type(2) {
      width: 200px;
    }

    &:nth-of-type(3) {
      width: 200px;
    }

    &:nth-of-type(4) {
      width: 200px;
    }
  }
}