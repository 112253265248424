@import 'mixins.scss';

.root {
  padding: 5px 10px;
  box-sizing: border-box;

  .popoverItem {
    padding-top: 5px;
    padding-bottom: 5px;
    box-sizing: border-box;
    @include text;
    cursor: pointer;
    border-bottom: 1px solid rgba(0, 0, 0, 0.116);
    display: flex;
    align-items: center;

    .icon {
      margin-right: 10px;
      color: #464646;
      font-size: 16px;
    }
  }
}