@import 'mixins.scss';

.root {
  width: 100%;
  display: flex;

  .textContainer {
    @include text;
    margin-top: 5px;
    width: 50%;
    padding-right: 10px;
    box-sizing: border-box;

    .text {
      display: flex;
      align-items: center;
      margin: 5px 0px;
      .bold {
        font-weight: bold;
        margin-right: 5px;
      }
    }
  }

  .playlistContent {
    width: 50%;
    border: 1px solid #afafaf24;
    border-radius: 4px;
    height: auto;
    overflow: hidden;
  }
}

.link {
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.desc {
  word-break: break-all;
}
