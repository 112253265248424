.root {
  align-items: center;
  width: 99%;
  padding: 10px 0;
  box-sizing: border-box;
  border-bottom: 1px solid #eef2f4;
  display: grid;
  grid-template-columns: 3fr 1fr 2fr 1fr 100px;

  .name {
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 22px;
    color: #333333;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    white-space: break-spaces;
  }
}

.acceptBtn {
  display: flex;
  align-items: center;
  height: 30px;
  border: none;
  background: #343b4c;
  border-radius: 15px;
  color: #ffffff;
  padding: 0 15px;
  transition: all 1s ease;
  font-weight: normal;
  font-size: 12px;
  cursor: pointer;

  &:disabled {
    background: #838999;
  }
}
