@import 'variables.scss';

.root {
  display: flex;
  align-items: center;
  margin-top: 30px;
  border-bottom: 1px solid #eef2f4;
  display: grid;
  grid-template-columns: 3fr 100px 95px;

  .headerIteam {
    user-select: none;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: $gray_one;

    &:nth-of-type(3) {
      text-align: right;
      padding-right: 50px;
      box-sizing: border-box;
    }
  }
}
