@import 'variables.scss';

.root {
  position: absolute;
  left: 78px;
  top: 19px;
  width: calc(100% - 83px);
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 285px;

  .title,
  .description {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: $gray_one;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    font-weight: 600;
    font-size: 12px;
    white-space: nowrap;
  }

  .description {
    max-width: 150px;
    font-weight: 400;
  }

  .dash {
    margin-right: 10px;
    margin-left: 10px;
  }
}

@media screen and (max-width: 800px) {
  .root {
    left: 216px;
    top: 9px;
  }
}
