.icon {
  fill: rgb(51, 51, 51);
  width: 16px;
  height: 16px;
  transform: rotate(180deg);
  transition: all 0.7s ease;
  margin-right: 4px;
  cursor: pointer;
  position: absolute;
  right: 4px;

  &.open {
    transform: rotate(-180deg);
    transition: all 0.7s ease;
  }
}
