// @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500;600&display=swap');
@import 'mixins.scss';

.root {
  // font-family: 'Poppins', sans-serif;
  overflow: auto;
  @include root-container;
  padding: 0;

  .content {
    @include root-content;
    padding-top: 0;
    padding-bottom: 0;
    height: calc(100% - 10px);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.popup {
  padding: 20px 0;
  position: relative;

  .loading {
    position: absolute;
    top: calc(50% - 15px);
    left: calc(50% - 15px);
  }

  .head,
  .product {
    font-weight: bold;
    font-size: 18px;
    color: #333333;
    margin-bottom: 14px;
  }

  .label,
  .text {
    font-weight: normal;
    font-size: 14px;
    color: #686868;
    margin-bottom: 14px;
  }
}

.product {
  margin-left: 5px;
}

.checkIcon {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.checkbox {
  display: flex;
  align-items: center;

  .labelCbx {
    height: 42px;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #686868;
    margin-left: -15px;

    &.selected {
      font-weight: bold;
    }
  }
}

.inputContainer {
  height: 62px;
  .input {
    transition: all 0.6s ease;
  }
}
