@import 'variables.scss';

.root {
  display: flex;
  justify-content: center;

  margin-top: 30px;
  width: calc(100% - 25px);
  box-shadow: 2px 1px 11px 3px rgba(181, 181, 181, 40%);
  border-radius: 15px;
  margin: 10px auto;
  background: #fff;
  padding: 10px;
  box-sizing: border-box;
  padding-top: 30px;

  .container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 250px;
    row-gap: 10px;
  }

  .input {
    border-color: #b8b9b9;
    padding: 0 0 0 10px;
    height: 45px;
    width: 250px;
  }
  .btn {
    height: 45px;
    border: none;
    background: #343b4c;
    border-radius: 15px;
    color: #ffffff;
    padding: 0 15px;
    transition: all 1s ease;
    font-weight: normal;
    font-size: 16px;
    cursor: pointer;
    width: 100%;

    &:disabled {
      cursor: not-allowed;
      background: #b8b9b9;
      color: #e3e9f1;
    }
  }
}

.fakePlaceholder {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  color: $gray_five;
  position: relative;
  top: 2px;
  left: 0px;
}
