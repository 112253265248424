$white: #fff;
$gray_one: #464646;
$gray_two: #333333;
$gray_three: #f9fafb;
$gray_fourth: #eef2f4;
$gray_five: #686868;
$black: #000;
$red: red;
$player_black: #0d0d0d;
$site_bg: #fafafa;
$bg: #f2f6f9;
$placeholder: #8d96b2;
$blue_color: #005cb2;
$blue_color_hover: #6ab7ff;

$bg_image_login: no-repeat url(../../assets/img/bg_login.png);
$message_border: 1px solid rgba(156, 158, 156, 0.2);
$message_border_one: 1px solid #e9e7e7;
$bg_gray: rgba(255, 255, 255, 0.432);
$bg_selected_tab: #f0f0f085;

$border_radius: 15px;

$margins: (
  a: auto,
  0: 0px,
  2: 2px,
  3: 3px,
  5: 5px,
  6: 6px,
  8: 8px,
  10: 10px,
  12: 12px,
  15: 15px,
  16: 16px,
  20: 20px,
  30: 30px,
  40: 40px,
  50: 50px,
  60: 60px,
  80: 80px,
  100: 100px,
);
$paddings: (
  0: 0px,
  5: 5px,
  10: 10px,
  15: 15px,
  16: 16px,
  20: 20px,
  30: 30px,
  40: 40px,
  50: 50px,
  60: 60px,
);
