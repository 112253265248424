.root {
  .header {
    text-align: center;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 20px;
    color: #343b4c;
    margin-top: 40px;
  }

  .subHead {
    width: 320px;
    height: 23px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    margin: 0px auto 45px auto;
    text-align: center;
    color: #8d96b2;
  }

  .formWrap {
    height: 261px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .adminForm {
      height: 185px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .form {
    height: 212px;
  }

  .or {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #8d96b2;
  }
}
