.root {
  max-width: 1440px;
  width: 100%;
  display: flex;

  .headerContainer {
    width: 100%;
    display: flex;
  }
}

.btn {
  width: 73px;
  height: 32px;
  border: 1px solid rgba(8, 28, 57, 0.2);
  border-radius: 5px;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: inherit;
  margin-top: 32px;
  margin-left: 32px;
  cursor: pointer;
  transition: border-color 1s ease;
  position: absolute;

  .btnText {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #0c2447;
    font-family: 'Poppins', sans-serif;
    margin-left: 8px;
  }

  &:hover {
    transition: border-color 1s ease;
    border: 1px solid rgba(8, 28, 57, 0.8);
  }
}

.mainHeaderContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  user-select: none;

  .head {
    font-weight: 600;
    font-size: 48px;
    line-height: 72px;
    text-align: center;
    // color: #0c2447;
    color: #333333;
    margin-bottom: 8px;
    margin-top: 28px;
  }

  .subHead {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    margin-top: 0;
    margin-bottom: 0;
    // color: #203a62;
    color: #8d96b2;
  }
}