  .item {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    height: 30px;
    color: #464646;
    border-bottom: 1px solid #e0e0e0;
    width: 70px;
    text-align: left;
    display: flex;
    align-items: center;
  }

  .input {
    padding-left: 20px;
  }

  .btn {
    background: inherit;
    height: auto;
    color: #fff;
    border: none;
    cursor: pointer;
    width: auto;
  }