.root {
  position: absolute;
  right: 30px;
  top: -8px;
  .content {
    cursor: pointer;
    top: -8px;
    position: absolute;
    right: 4px;

    &.disabled {
      cursor: not-allowed;

      svg {
        fill: #cdcdcd;
      }
    }
  }
}
