@import '../../../variables.scss';

.musician-content {
  width: 100%;
}

.info-container-mus {
  width: calc(100% - 25px);
  box-shadow: 2px 1px 11px 3px rgba(181, 181, 181, 0.4);
  border-radius: $border-radius;
}

.info-c {
  width: 100%;
}

.root {
  background: #fff;
  border-radius: $border_radius;
  margin: 10px;
  padding: 20px;
  box-shadow: 2px 1px 11px 3px rgba(181, 181, 181, 0.4);
  box-sizing: border-box;
  height: calc(100vh - 190px);
  overflow: auto;
}

.container {
  display: flex;
  margin-top: 51.09px;
}

.videoThumbnailWrapper {
  width: 50%;
  display: flex;
  align-items: flex-start;
  overflow: auto;
  position: relative;

  @media (max-width: 768px) {
    width: 100%; // Full width for smaller screens
    flex-direction: column; // Stack the content in a column
  }
}

.promotionLabelsAndFormWrapper {
  width: 50%;
  display: flex;
  overflow: auto;

  @media (max-width: 768px) {
    width: 100%; // Full width for smaller screens
    flex-direction: column; // Stack the content in a column
    margin-bottom: 16px; // Add spacing between stacked items if needed
  }
}

.promotionLabelsAndFormWrapper .promotionLabelsBox {
  max-width: 524.51px;
  margin: 0 auto;
  height: auto;
}

.promotionLabelsAndFormWrapper .promotionLabelsBox p.labelPara {
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  margin: 0px;
}

.promotionLabelsAndFormWrapper .promotionLabelsBox h1.labelHead {
  font-size: 83px;
  line-height: 83px;
  font-weight: 700;
  margin: 32px 0px;
}

.promotionLabelsAndFormWrapper .promotionLabelsBox p.labelDesc {
  font-size: 19px;
  font-weight: 700;
  line-height: 26.79px;
  margin: unset;
}

.promotionLabelsAndFormWrapper .promotionFormBox {
  max-width: 534px;
  margin-top: 39.91px;
  padding: 13px 20px 13px 0px;
  border-radius: 35px;
}

.promotionForm {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.promotionForm input.inputEmail {
  font-weight: 600;
  font-size: 18px;
  color: #757575;
  line-height: 16.02px;
  border: transparent;
  height: 16px;
}

.promotionForm input.inputEmail:focus {
  outline: none;
}

.promotionForm button.submitPromotionMail {
  display: flex;
  justify-content: space-between;
  background: #3706FD;
  color: #fff;
  border-radius: 25px;
  padding: 19px 29px;
  font-size: 16px;
  font-weight: 700;
  border: transparent;
  width: 160.17px;
  height: 52px;
  cursor: pointer;
}

.promotionForm button.submitPromotionMail:disabled {
  background-color: #B9B9B9;
  cursor: default;
  opacity: 1;
  pointer-events: none;
}

.promotionForm button.submitPromotionMail:hover,
.promotionForm button.submitPromotionMail:focus {
  opacity: 0.8;
  transition: 0.5s ease-in-out;
}

.videoThumbnailWrapper img {
  height: 411.36px;
  width: 600.82px;
  border-radius: $border_radius;
  object-fit: cover;
  object-position: center;
  cursor: pointer;
}

.videoThumbnailWrapper .skeletonLoader {
  width: 600.82px;
  height: 411.36px;
  background: linear-gradient(90deg, #eee 25%, #ddd 50%, #eee 75%);
  background-size: 200% 100%;
  border-radius: 15px;
  animation: shimmer 1.5s infinite linear;
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }

  100% {
    background-position: 200% 0;
  }
}

.videoThumbnailWrapper .thumbnail {
  cursor: pointer;
  transition: opacity 0.5s ease-in-out;
}

.videoThumbnailWrapper .hidden {
  display: none;
}

.videoThumbnailWrapper .visible {
  display: block;
  opacity: 1;
}

.videoPopupWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000bd;
  z-index: 1000;
}

.videoPopupWrapper .playerWrapper {
  position: relative;
  max-width: 800px;
  margin: 0 auto;
  background: #000;
  border-radius: $border_radius;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
  z-index: 2;
  padding: 10px;
}

.videoPopupWrapper .videoPlayer {
  width: 100%;
  height: auto;
  border-radius: $border_radius;

  &::-webkit-media-controls {
    opacity: 1 !important;
    display: flex !important;
    pointer-events: all;
  }

  &:hover::-webkit-media-controls-panel {
    display: flex !important;
    opacity: 1 !important;
  }
}

.videoPopupWrapper .overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 900;
  cursor: pointer;
}

.videoPopupWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  animation: fadeIn 0.3s ease;
}

.videoPopupWrapper .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
}

@keyframes fadeIn {
  from {
    opacity: 0;

    .playerWrapper {
      position: relative;
      max-width: 800px;
      margin: 0 auto;
      background: #000;
      border-radius: $border_radius;
      overflow: hidden;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
    }

    .videoPopupWrapper .videoPlayer {
      width: 100%;
      height: auto;
      display: block;
    }
  }

  to {
    opacity: 1;
  }
}

.videoPopupWrapper .closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 5px;
  z-index: 3;

  svg {
    transition: transform 0.2s ease, stroke 0.2s ease;
  }

  &:hover svg {
    transform: scale(1.1);
    stroke: white;
  }
}

.playBtnOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 600.82px;
  height: 411.36px;
  background: rgba(0, 0, 0, 0.26);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  transition: background 0.3s ease-in-out;
  cursor: pointer;
}

.playBtnOverlay:hover {
  background: rgba(0, 0, 0, 0.5);
}

.playBtnOverlay .playIcon {
  color: white;
  font-size: 90px;
  transition: transform 0.2s ease-in-out;
}

.playBtnOverlay .playIcon:hover {
  transform: scale(1.1);
}

.optedInMsg {
  width: calc(100% - 60px);
  margin: 0px auto;
  height: 64px;
  background: #fff;
  padding: 0 20px;
  display: flex;
  align-items: center;
  position: relative;
  box-shadow: 2px 1px 11px 3px rgba(181, 181, 181, 0.4);
  margin-top: 10px;
  background: #0077c0;
  border-radius: 15px;
  color: white;
  font-size: 18px;
  font-weight: bold;
  text-shadow: 2px 1px 11px #969696;
}

.infoBox {
  background: linear-gradient(135deg, #ebf8ff, #dbeeff);
  border-left: 5px solid #3182ce;
  color: #2c5282;
  padding: 10px;
  border-radius: 15px;
  display: flex;
  align-items: flex-start;
  gap: 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
  display: flex;
  align-items: flex-start;
}

.infoLink {
  font-weight: bold;
  color: #1e4e8c;
  text-decoration: underline;
  margin-left: 5px;
}

.infoPara {
  font-size: 16px;
  font-weight: 500;
  line-height: 26.79px;
}

.mt32 {
  margin-top: 32px;
}

.rootAlert {
  height: calc(100vh - 263px);
}