@import 'variables.scss';

.root {
  width: 100%;
  height: calc(100% - 40px);

  .jobs {
    height: calc(100vh - 225px);
    overflow: auto;
    position: relative;
  }
}

.nextBtn {
  outline: none;
  border: none;
  background: inherit;
  color: $blue_color;
  position: absolute;
  bottom: 6px;
  left: calc(50% - 40px);
  cursor: pointer;
  &:hover {
    color: $blue_color_hover;
  }

  &:disabled {
    color: $blue_color_hover;
    cursor: not-allowed;
  }
}
