@import '../../variables.scss';

.btn {
  color: $gray_one;
  border: none;
  background: inherit;
  text-transform: uppercase;
  outline: none;
  width: 60px;

  &:hover {
    cursor: pointer;
  }
}

.allBtn,
.musicBtn,
.sfxBtn,
.dialogBtn {
  font-weight: bold;
  text-decoration: underline;
  text-transform: uppercase;
  outline: none;
  color: #343b4c;
  font-size: 14px;

  &:hover {
    cursor: pointer;
  }
}
