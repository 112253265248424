@import '../../../variables.scss';

.root {
  display: flex;
  width: calc(100% - 10px);
  background: $site_bg;
  justify-content: center;
  margin-left: 5px;
}

@media screen and (max-width: 1360px) {
  .company-info {
    width: calc(100% - 100px);
  }

  .root {
    .info {
      width: calc(100% - 100px);
    }
  }
}
