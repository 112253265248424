.root {
  height: calc(100% - 96px);
  overflow: hidden;

  .loadingContainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
