@import './variables.scss';

.root {
  height: 100vh;
  overflow: hidden;
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    background: $white;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    z-index: 10001;

    .loader {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
}

.main,
.mainFullWidth {
  display: flex;
  flex-direction: column;
  width: calc(100% - 80px);
  height: 100vh;
  overflow: hidden;
  background: $site_bg;
}

.mainFullWidth {
  width: 100%;
}

.appContent {
  display: flex;
  overflow: hidden;
  max-height: 100vh;
}
