@import '../../variables.scss';

.label {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: $gray_two;
  margin-bottom: 20px;
  margin-top: 10px;
  text-align: center;
}

.btnContainer {
  height: 32px;
  background: inherit;
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: $gray_two;
  cursor: pointer;
  transition: all 1s ease;
  display: flex;
  align-items: center;
  justify-content: baseline;
  padding: 0;
  margin-top: 5px;
  border-bottom: 1px solid #eef2f4;
  width: 100%;
}

.button {
  display: flex;
  align-items: center;
  .btnContent {
    margin-top: 5px;
  }
}

.icon {
  path {
    color: $gray_one;
  }
  margin-right: 10px;
  margin-left: 3px;
}
