@import 'mixins.scss';

.root {
  display: flex;
  flex-direction: column;

  .title,
  .subtitle {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: $gray_one;
  }

  .title {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .subtitle {
    margin-bottom: 14px;
    font-weight: 400;
    text-align: justify;
  }

  .body,
  .footer {
    display: flex;
    flex-direction: column;
  }
  .link {
    margin-top: 10px;
    a {
      font-size: 14px;
      color: #3983f2;
      display: block;
    }
  }

  .surge {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    color: $gray_two;
  }
}
