@import 'mixins.scss';

.root {
  width: 100%;
  background: #fff;
  padding: 40px 0;
  box-sizing: border-box;
  overflow: hidden;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 15px;
  max-width: 1000px;
  line-height: 1.6;
  text-align: justify;

  .wrapper {
    padding: 0 40px 40px 40px;
    overflow: auto;
    height: calc(100vh - 200px);
    box-sizing: border-box;
  }

  .btn,
  .btnBack {
    @include btnPrimary;
    margin-top: 50px;
    margin-left: 20px;
    display: block;
  }

  .btnBack {
    margin-left: auto;
    background: inherit;
    color: #343b4c;
    border: 2px solid #343b4c;
  }

  .buttons {
    display: flex;
    align-items: center;
  }
}

.container {
  .subText {
    font-weight: bold;
    margin-right: 5px;
    margin-top: 5px;
  }

  .text {
    display: inline;
  }
}

.containerNote {
  padding-left: 40px;
  box-sizing: border-box;
  display: flex;

  .subText {
    font-weight: bold;
    margin-right: 5px;
  }

  .text {
    display: inline;
  }
}

.bold {
  font-weight: bold;
}

.agreeContainer {
  .subText {
    font-weight: bold;
    margin: 10px 0;
  }
}
