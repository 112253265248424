@import 'variables.scss';

.root {
  width: calc(100% - 60px);
  height: calc(100% - 60px);
  min-height: 300px;
  padding: 20px;
  background: $white;
  border-radius: 15px;
  overflow: auto;
  box-shadow: 2px 1px 11px 3px rgba(181, 181, 181, 0.4);

  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: $gray_two;
  }

  .header {
    display: grid;
    grid-template-columns: 50px 0.8fr 2fr 0.4fr 1fr;
    align-items: center;
    margin-top: 30px;

    .headerName {
      margin-left: 10px;
    }

    .headerName,
    .headerDescription,
    .headerOwner,
    .headerMembers {
      user-select: none;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: $gray_one;
      cursor: pointer;
    }
  }

  .table {
    user-select: none !important;
    height: calc(100% - 70px) !important;
  }
}

.loading {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
