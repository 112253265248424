.root {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  width: calc(100% - 25px);
  box-shadow: 2px 1px 11px 3px rgba(175, 175, 175, 0.4);
  border-radius: 15px;
  margin: 10px auto 20px auto;
  padding: 30px;
  box-sizing: border-box;

  .link {
    font-size: 15px;
    color: #3983f2;
  }
}
