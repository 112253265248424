@import 'variables.scss';

.root {
  width: calc(100% - 20px);
  height: calc(100vh - 70px);
  margin: 0 auto;
  background: $site_bg;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  opacity: 1;
  overflow: hidden;
  overflow-y: auto;
  box-shadow: 2px 1px 11px 3px rgba(181, 181, 181, 0.4);
  background: $white;

  .header {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 10px;

    .headerContent {
      display: flex;
      align-items: center;
    }

    .tabs {
      width: 300px;
      margin-left: 20px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-right: 20px;
      margin-left: auto;
      margin-bottom: 15px;

      .btn {
        background: inherit;
        border: none;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        text-transform: uppercase;
        color: $gray_one;
        cursor: pointer;
        outline: none;
        margin: 0px 10px;
      }

      .active {
        background: $gray_fourth;
        border-radius: $border_radius;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        color: $gray_two;
        border: none;
        cursor: pointer;
        outline: none;
        margin: 0px 10px;
      }
    }

    .title {
      margin-right: 20px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      color: $gray_two;
    }
  }
}

.content {
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
}

.fakePlaceholder {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  color: $gray_five;
  position: absolute;
  bottom: 28px;
}

.directionBtn {
  display: block;
  width: 30px;
  height: 30px;
  line-height: 1;
  background: inherit;
  border: 1px solid #efefef;
  border-radius: 15px;
  cursor: pointer;
  margin-right: 30px;

  &.disabled {
    background: #bdbdbd;
    cursor: not-allowed;
  }
}

@media screen and (max-width: 1360px) {
  .header {
    width: calc(100% - 100px);
  }
}

.switch {
  width: 300px;
  position: absolute;
  left: 130px;
}
