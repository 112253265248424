@import 'variables.scss';

.links {
  overflow: auto;
  height: 139px !important;
  width: 99%;
}

.createPlaylist {
  padding-left: 8px;
  cursor: pointer;
}

.unsaved {
  font-size: 11px;
  padding: 2px 4px;
  border-radius: 4px;
  margin-right: 7px;
  color: $gray_two;
  background: $gray_fourth;
  cursor: default;
}

.options {
  padding: 10px 15px;
  box-sizing: border-box;
}
.option {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: $gray_two;
  margin-bottom: 4px;
  font-size: 13px;
  padding: 5px;
  width: 100%;
  box-sizing: border-box;

  &:hover {
    background-color: $gray_fourth;
  }
  .icon {
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 20px;
    height: 20px;
    .optionsIcon {
      max-width: 20px;
      fill: $gray_two;
      height: 18px;
    }
  }
}

.deleteBtn,
.sharedOption {
  width: 100%;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  border: none;
  background: transparent;
  color: $gray_two;
  border-radius: 0;

  &:hover {
    background: transparent !important;
    border-radius: 0;
  }
}

.sharedOption {
  justify-content: baseline !important;
  font-size: 13px !important;
  padding: 0 !important;
  color: $gray_two !important;
}

:global(.MuiIconButton-label) {
  justify-content: flex-start !important;
}
