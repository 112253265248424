@import './variables.scss';
@import './mixins.scss';

@each $margin, $pixels in $margins {
  .m-l-#{$margin} {
    @include margin-left-variant($pixels);
  }
  .m-r-#{$margin} {
    @include margin-right-variant($pixels);
  }
  .m-t-#{$margin} {
    @include margin-top-variant($pixels);
  }
  .m-b-#{$margin} {
    @include margin-bottom-variant($pixels);
  }
  .m-#{$margin} {
    @include margin-variant($pixels);
  }
}

@each $padding, $pixels in $paddings {
  .p-l-#{$padding} {
    @include padding-left-variant($pixels);
  }
  .p-r-#{$padding} {
    @include padding-right-variant($pixels);
  }
  .p-#{$padding} {
    @include padding-variant($pixels);
  }
  .p-t-#{$padding} {
    @include padding-top-variant($pixels);
  }
  .p-b-#{$padding} {
    @include padding-bottom-variant($pixels);
  }
}
