@import 'variables.scss';

.root {
  display: grid;
  grid-template-columns: 40px 1.5fr 1.5fr 100px 1.5fr 1fr;
  .item {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: $gray_one;
  }
}
