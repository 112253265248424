@import 'mixins.scss';
@import 'variables.scss';

.root {
  @include root-container;
  width: 100%;

  .content {
    @include root-content;
  }

  .head {
    margin-top: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: $gray_two;
    user-select: none;
  }
}
