@import 'mixins.scss';

.root {
  display: flex;
  align-items: center;
  user-select: none;

  .playBtn {
    width: 15px;
    margin-top: 2px;
    cursor: pointer;
  }

  .title {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #464646;
    margin-left: 20px;
    width: 200px;
    @include text-overflow;
  }

  .time {
    margin-left: auto;
    font-size: 11px;
    font-weight: bold;
    color: #464646;
    margin-right: 5px;
  }
}

.selectContainer {
  width: 40px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  .disabled {
    cursor: not-allowed;
  }
}

.toggleAddBtn {
  padding: 0;
  box-sizing: border-box;
  cursor: pointer;
  border: none;
  background: none;
  outline: none;

  &:disabled {
    cursor: not-allowed;
  }
}
