@import 'mixins.scss';

.root {
  width: 100%;
  background: #fff;
  padding: 40px 0;
  box-sizing: border-box;
  overflow: hidden;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 15px;
  max-width: 1000px;
  line-height: 1.6;
  text-align: justify;

  .wrapper {
    padding: 0 40px 40px 40px;
    padding: 0 40px 40px 40px;
    overflow: auto;
    height: calc(100vh - 200px);
    box-sizing: border-box;
  }

  .subtextContainer {
    padding-left: 20px;
    box-sizing: border-box;
    display: flex;
    margin-top: 15px;

    .bold {
      font-weight: bold;
      margin-right: 15px;
    }
  }
}

.bold {
  font-weight: bold;
}

.subContainer {
  padding-left: 40px;
  box-sizing: border-box;
  display: flex;
  margin-top: 15px;

  .boldText {
    font-weight: bold;
    margin-left: 15px;
  }
}

.message {
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
}

.btn,
.btnBack {
  @include btnPrimary;
  margin-top: 50px;
  margin-left: 20px;
  display: block;
}

.btnBack {
  margin-left: auto;
  background: inherit;
  color: #343b4c;
  border: 2px solid #343b4c;
}

.buttons {
  display: flex;
  align-items: center;
}

.subHead {
  display: flex;

  .number {
    margin-right: 6px;
  }
}
