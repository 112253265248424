.root {
  width: 100%;
  padding: 0 0 0 20px;
  box-sizing: border-box;
  height: calc(100vh - 286px);

  .container {
    display: flex;
  }

  .tracks {
    width: calc(100% - 391px);
  }
}

.notice {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
