@import 'variables.scss';

.head {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: $gray_one;
  user-select: none;
}

.root {
  margin-bottom: 10px;
  margin-top: 8px;
}

.creator {
  display: grid;
  grid-template-columns: 50px 40px 2fr 100px 1.5fr 1fr;
}

.manager {
  display: grid;
  grid-template-columns: 50px 40px 2fr 100px 1.5fr 0.5fr 0.5fr;
}

.sorting {
  font-weight: bold;
}
