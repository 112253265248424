.root {
  width: 100%;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .title {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 10px;
  }
}
