@import 'mixins.scss';

.root {
  margin-top: 12px;
  height: 120px;
  overflow-y: auto;
  @include scrollbar;

  .link {
    display: flex;
    align-items: center;
    height: 40px;

    .iconContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      transition: all 1s ease;

      .iconDisabled {
        fill: #c6c6c6;
        transition: all 1s ease;
      }

      .icon {
        fill: #333333;
        transition: all 1s ease;
      }

      .iconSelected {
        fill: rgb(35, 199, 35);
        transition: all 1s ease;
      }
    }
  }
}

.iconWrapper {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
