@import '../../variables.scss';

.loadingRoot {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  z-index: 10000;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
}

.successContainer {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  z-index: 10;

  .successWrap {
    position: relative;
    width: 320px;
  }

  .icon {
    color: green;
  }
}
.root {
  width: 100%;
  height: 100vh;
  background: url('../../assets/img/bg_login.png');
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;

  display: flex;
  justify-content: center;
  align-items: center;

  .backButton {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 1s ease;
    position: absolute;
    top: 30px;
    left: 20px;

    &:hover {
      .icon {
        opacity: 0.6;
      }
    }

    .icon {
      font-size: 20px;
      opacity: 1;
      margin-left: 3px;
    }

    .text {
      color: $gray_one;
      font-weight: bold;
    }
  }

  .form {
    width: 450px;
    height: 300px;
    background: $white;
    padding: 10px;
    -webkit-box-shadow: 2px 3px 15px -1px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 2px 3px 15px -1px rgba(0, 0, 0, 0.75);
    box-shadow: 2px 3px 15px -1px rgba(0, 0, 0, 0.75);
    border-radius: $border_radius;
    display: flex;
    align-items: center;
    justify-content: center;

    .fieldset {
      border: 1px solid rgba(52, 59, 76, 0.39);
      border-radius: $border_radius;
    }

    .pharagraph {
      font-size: 12px;
      margin-bottom: 20px;
      margin-top: 0;
      width: 310px;
    }

    .legend {
      margin-left: -3px;
      padding: 5px;
    }

    .wrapper {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }
}
