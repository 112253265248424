@import 'mixins.scss';
.root {
  padding: 20px;
  box-sizing: border-box;

  .title {
    @include table-head;
  }

  .text {
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    color: #686868;
  }

  .bold {
    font-weight: bold;
    margin-left: 5px;
    margin-right: 4px;
  }
}
