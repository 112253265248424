@import 'mixins.scss';

.root {
  height: calc(100% - 174px);
  padding: 20px;
  background: #fff;
  border-radius: 15px;
  margin-top: 10px;
  width: calc(100% - 62px);
  box-shadow: 2px 1px 11px 3px rgba(181, 181, 181, 0.4);

  .title {
    @include table-head;
    color: $gray_one;
    margin-bottom: 10px;
  }

  .scroll {
    display: flex;
    flex-direction: column;
    height: calc(100% - 62px) !important;
    overflow: auto;
    position: relative;

    .content {
      min-height: 42px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid $gray_fourth;
      width: 98%;

      .name {
        width: 400px;
        @include table-big-text-without-width;
        color: $gray_one;
        font-size: 13px;
        font-weight: bold;
      }

      .upload,
      .listen,
      .share {
        width: 200px;
        @include table-big-text-without-width;
        color: $gray_two;
        font-size: 13px;
      }
    }
  }
}

.loadingContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;

  &.hidden {
    display: none;
  }
}
