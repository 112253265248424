.root {
  width: 70px;
  z-index: 10000;
  margin-left: 5px;
}

.loader {
  width: 140px;
  display: block;
  position: static;
}