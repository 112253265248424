@import 'mixins.scss';

.root {
  height: 100%;
  position: relative;

  .body {
    height: 100%;
  }
  .header {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 5px;

    .btn {
      @include headerTab;
      background: inherit;
      transition: all 1s ease;
      font-size: 12px;

      &.selected {
        @include headerTab;
        font-size: 12px;
      }
    }
  }
}

.emptyCreatePlaylistBtn {
  display: block;
  margin-top: 20px;
  margin-left: 20px;
  cursor: pointer;
  border: none;
  padding: 5px;
  border-radius: 5px;
  background: #0e78e6;
  color: #fff;

  &.disabled {
    background: #81878d;
    cursor: not-allowed;
  }
}

.input {
  padding-left: 20px;
}

.loader {
  width: 100%;
  height: 167px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 100px;
  left: 10px;
}

.addLink {
  padding: 1px 5px;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 3px;
  margin-left: 10px;
  margin-top: 5px;
}

.popoverBtn {
  padding: 0;
  border: none;
  outline: none;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: inherit;
  cursor: pointer;
}

.link {
  cursor: pointer;
  outline: none;
  margin: 0px 10px;
  border-radius: 15px;
  padding: 0;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: inherit;

  &.selected {
    background: #eef2f4;
  }
}
