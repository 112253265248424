.root {
  display: flex;
  align-items: center;
  justify-content: center;
  row-gap: 24px;
  column-gap: 24px;
  margin-top: 32px;
  max-width: 1440px;
  width: 100%;
  user-select: none;
  outline: none;

  .prev,
  .next {
    position: absolute;
    top: 50%;
    width: 30px;
    height: 30px;
    border: 1px solid rgba(8, 28, 57, 0.5);
    border-radius: 5px;
    outline: none;
    background: inherit;
    cursor: pointer;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #0c2447;
    font-family: 'Poppins', sans-serif;
    transition: border 1s ease-in;
    z-index: 1;

    &:hover {
      border: 1px solid rgba(8, 28, 57, 0.5);
      transition: border 1s ease-in;
    }

    display: none;
  }

  .prev {
    left: 20px;
  }

  .next {
    right: 20px;
  }
}

@media screen and (max-width: 1040px) {
  .root {
    .prev {
      display: block;
    }

    .next {
      display: block;
    }
  }
}