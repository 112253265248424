.root {
  height: 54px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .label {
    color: red;
    text-decoration: underline;
  }
}
